import React, { useMemo } from "react";
import type { ReactElement } from "react";

import InvoiceStatus from "../Invoicing/InvoiceStatus";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import type { LoadInvoices_query_invoices$data } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoices_query_invoices.graphql";

import { listWithAndConverter } from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<
        NonNullable<
            NonNullable<
                LoadInvoices_query_invoices$data["invoices"]
            >["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressInvoice: (invoiceId: string | null | undefined) => void;
    onPressProfile: (
        profileId: string | null | undefined,
        accountType: "PARENT" | "PUPIL",
    ) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const InvoiceTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressInvoice,
        onPressProfile,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        const childrenFirstNames = data.assignedTo?.children.edges
            .map((item) => {
                if (!item?.node?.user) {
                    return;
                }

                return item.node.user.firstName;
            })
            .filter((x): x is NonNullable<typeof x> => Boolean(x));

        return [
            {
                data: data.assignedTo?.accountNumber ?? "",
                onPress: () => onPressProfile(data.assignedTo?.id, "PARENT"),
            },
            {
                data: getFullName(
                    data.assignedTo?.user.firstName,
                    data.assignedTo?.user.lastName,
                ),
                onPress: () => onPressProfile(data.assignedTo?.id, "PARENT"),
            },
            {
                data: data.assignedTo?.user.email ?? "",
                onPress: () => onPressProfile(data.assignedTo?.id, "PARENT"),
            },
            {
                data: data.assignedTo?.phoneNumber ?? "",
                onPress: () => onPressProfile(data.assignedTo?.id, "PARENT"),
            },
            {
                data: childrenFirstNames?.length
                    ? listWithAndConverter(childrenFirstNames.join(", "))
                    : "",
                onPress: childrenFirstNames?.length
                    ? () => {
                          if (
                              childrenFirstNames?.length === 1 &&
                              data.assignedTo?.children.edges[0]?.node?.id
                          ) {
                              onPressProfile(
                                  data.assignedTo?.children.edges[0]?.node?.id,
                                  "PUPIL",
                              );
                          } else {
                              onPressProfile(data.assignedTo?.id, "PARENT");
                          }
                      }
                    : undefined,
            },
            {
                data: data.totalAmount !== null ? `£${data.totalAmount}` : "",
                onPress:
                    data.totalAmount !== null
                        ? () => onPressInvoice(data.id)
                        : undefined,
            },
            {
                data: data.status ? (
                    <InvoiceStatus
                        isTransparent
                        isUpdatedDraft={data.isUpdate}
                        status={data.status}
                        textProps={{
                            fontFamily: "Poppins-Regular",
                        }}
                    />
                ) : (
                    ""
                ),
                onPress: data.status
                    ? () => onPressInvoice(data.id)
                    : undefined,
            },
            {
                data: data.reminder1SentTimestamp ? (
                    <InvoiceStatus
                        isTransparent
                        status={"SENT"}
                        textProps={{
                            fontFamily: "Poppins-Regular",
                        }}
                    />
                ) : (
                    ""
                ),
                onPress: data.reminder1SentTimestamp
                    ? () => onPressInvoice(data.id ?? undefined)
                    : undefined,
            },
            {
                data: data.reminder2SentTimestamp ? (
                    <InvoiceStatus
                        isTransparent
                        status={"SENT"}
                        textProps={{
                            fontFamily: "Poppins-Regular",
                        }}
                    />
                ) : (
                    ""
                ),
                onPress: data.reminder2SentTimestamp
                    ? () => onPressInvoice(data.id ?? undefined)
                    : undefined,
            },
            {
                data: data.reminder3SentTimestamp ? (
                    <InvoiceStatus
                        isTransparent
                        status={"SENT"}
                        textProps={{
                            fontFamily: "Poppins-Regular",
                        }}
                    />
                ) : (
                    ""
                ),
                onPress: data.reminder3SentTimestamp
                    ? () => onPressInvoice(data.id ?? undefined)
                    : undefined,
            },
        ];
    }, [
        data.assignedTo?.children.edges,
        data.assignedTo?.accountNumber,
        data.assignedTo?.user.firstName,
        data.assignedTo?.user.lastName,
        data.assignedTo?.user.email,
        data.assignedTo?.phoneNumber,
        data.assignedTo?.id,
        data.totalAmount,
        data.status,
        data.isUpdate,
        data.reminder1SentTimestamp,
        data.reminder2SentTimestamp,
        data.reminder3SentTimestamp,
        data.id,
        onPressProfile,
        onPressInvoice,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default InvoiceTableRow;
