import React, {
    useReducer,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import type { ReactElement } from "react";

import {
    Box,
    Modal,
    VStack,
    Text,
    HStack,
    Checkbox,
    ScrollView,
    Button,
} from "native-base";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import MultiSelectRow from "pianofunclub-shared/components/ListItems/MultiSelectRow";
import SchoolMultiSelectModal from "pianofunclub-shared/components/Modals/SchoolMultiSelectModal";
import TeacherMultiSelectModal from "pianofunclub-shared/components/Modals/TeacherMultiSelectModal";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import { ChevronLeftIcon } from "pianofunclub-shared/components/Other/Icons";

import { SCHOOL_YEARS } from "pianofunclub-shared/utils/constants";
import {
    listToStringWithCommasConverter,
    listWithAndConverter,
    messagePreviewConverter,
    schoolYearConverter,
    sendTypeConverter,
} from "pianofunclub-shared/utils/converters";
import { pushOrRemove } from "pianofunclub-shared/utils/helpers";
import { createReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    hideModal: () => void;
    sendBulkCommunication: (variables: {
        excludePupilsWhoHaveBook: boolean;
        excludePupilsWhoHaveInstrument: boolean;
        excludePupilsWhoHaveInstrumentSuppliedByUs: boolean;
        excludePupilsWhoHaveReturnedInstrument: boolean;
        messageString: string;
        messageSubject: string;
        schoolIds: string[];
        schoolYears: number[];
        sendType: string;
        teacherIds?: string[];
    }) => void;
    showModal: boolean;
}

type ReducerValues = {
    excludePupilsWhoHaveBook: boolean;
    excludePupilsWhoHaveInstrument: boolean;
    excludePupilsWhoHaveInstrumentSuppliedByUs: boolean;
    excludePupilsWhoHaveReturnedInstrument: boolean;
    hasSelectedAllSchoolYears: boolean;
    hasSelectedAllSchools: boolean;
    hasSelectedAllTeachers: boolean;
    messageString: string;
    messageSubject: string;
    selectedSchoolIds: string[];
    selectedSchoolNames: string[];
    selectedSchoolYears: string[];
    selectedTeacherIds: string[];
    selectedTeacherNames: string[];
    sendType: string;
};

type ReducerTypes = string | number | boolean | string[] | undefined;

const SendBulkCommunicationModal = (props: Props): ReactElement | null => {
    const { hideModal, sendBulkCommunication, showModal } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                selectedSchoolIds: [],
                selectedSchoolNames: [],
                hasSelectedAllSchools: true,
                selectedTeacherIds: [],
                selectedTeacherNames: [],
                hasSelectedAllTeachers: true,
                selectedSchoolYears: SCHOOL_YEARS.map((item) => item.value),
                hasSelectedAllSchoolYears: true,
                excludePupilsWhoHaveBook: false,
                excludePupilsWhoHaveInstrument: false,
                excludePupilsWhoHaveInstrumentSuppliedByUs: false,
                excludePupilsWhoHaveReturnedInstrument: false,
                sendType: "EMAIL_SMS",
                messageString: "",
                messageSubject: "",
                showSendBulkCommunicationProgressIndicator: false,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const schoolYearRowRefs = useRef(new Map());
    const messageSubjectInputRef = useRef(null);
    const messageStringInputRef = useRef<{ focus: () => void }>(null);

    const schoolYearPressHandler = useCallback(
        (variables: { id: string }) => {
            dispatchState({
                input: "selectedSchoolYears",
                value: pushOrRemove(
                    state.values.selectedSchoolYears,
                    variables.id,
                ),
            });
        },
        [state.values.selectedSchoolYears],
    );

    const messageFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string | undefined,
        ) => {
            if (
                typeof inputValue === "string" &&
                typeof inputIdentifier === "string"
            ) {
                dispatchState({ input: inputIdentifier, value: inputValue });
            }
        },
        [],
    );

    const renderSelectSchools = useMemo(() => {
        return (
            <SchoolMultiSelectModal
                buttonText="Continue"
                doNotHideOnSave
                hideCountFromButton
                hideModal={() => {
                    setCurrentPageIndex(0);
                    hideModal();
                }}
                initiallySelectAllSchools
                initiallySelectedSchoolNames={state.values.selectedSchoolNames}
                modalBodyProps={{
                    _scrollview: {
                        contentContainerStyle: {
                            justifyContent: "center",
                            flexGrow: 1,
                        },
                    },
                }}
                modalContentProps={{
                    height: "620px",
                    width: "800px",
                    maxWidth: "800px",
                }}
                onSave={({ hasSelectedAllSchools, schoolIds, schoolNames }) => {
                    dispatchState({
                        input: "selectedSchoolIds",
                        value: schoolIds,
                    });
                    dispatchState({
                        input: "selectedSchoolNames",
                        value: schoolNames,
                    });
                    dispatchState({
                        input: "hasSelectedAllSchools",
                        value: hasSelectedAllSchools,
                    });
                    setCurrentPageIndex(
                        (currentPageIndex) => currentPageIndex + 1,
                    );
                }}
                showModal={showModal && currentPageIndex === 0}
                title="Select Schools for Bulk Email/SMS"
                width={600}
            />
        );
    }, [
        currentPageIndex,
        hideModal,
        showModal,
        state.values.selectedSchoolNames,
    ]);

    const renderSelectTeachers = useMemo(() => {
        return (
            <TeacherMultiSelectModal
                buttonText="Continue"
                doNotHideOnSave
                hideCountFromButton
                hideModal={() => {
                    setCurrentPageIndex(0);
                    hideModal();
                }}
                includeBackButton
                initiallySelectAllTeachers
                initiallySelectedTeacherIds={state.values.selectedTeacherIds}
                modalBodyProps={{
                    _scrollview: {
                        contentContainerStyle: {
                            justifyContent: "center",
                            flexGrow: 1,
                        },
                    },
                }}
                modalContentProps={{
                    height: "620px",
                    width: "800px",
                    maxWidth: "800px",
                }}
                onBackButtonPress={() =>
                    setCurrentPageIndex(
                        (currentPageIndex) => currentPageIndex - 1,
                    )
                }
                onSave={({
                    hasSelectedAllTeachers,
                    teacherIds,
                    teacherNames,
                }) => {
                    dispatchState({
                        input: "selectedTeacherIds",
                        value: teacherIds,
                    });
                    dispatchState({
                        input: "selectedTeacherNames",
                        value: teacherNames,
                    });
                    dispatchState({
                        input: "hasSelectedAllTeachers",
                        value: hasSelectedAllTeachers,
                    });
                    setCurrentPageIndex(
                        (currentPageIndex) => currentPageIndex + 1,
                    );
                }}
                showModal={showModal && currentPageIndex === 1}
                title="Select Teachers for Bulk Email/SMS"
                width={600}
            />
        );
    }, [
        showModal,
        currentPageIndex,
        state.values.selectedTeacherIds,
        hideModal,
    ]);

    const renderSelectYearGroups = useMemo(() => {
        return (
            <>
                <VStack alignItems="center" flex={1} width="85%">
                    <Box
                        bg="surface.100"
                        borderColor="surface.200"
                        borderRadius="2xl"
                        borderWidth={1}
                        height="380px"
                        overflow="hidden"
                        width="100%">
                        <HStack
                            alignItems="center"
                            bg="primary.50"
                            borderBottomWidth={1}
                            borderColor="surface.200"
                            pb="2"
                            pt="3"
                            px="4"
                            space="3">
                            <Checkbox
                                isChecked={
                                    SCHOOL_YEARS.length <=
                                        state.values.selectedSchoolYears
                                            .length &&
                                    SCHOOL_YEARS.every((item) =>
                                        state.values.selectedSchoolYears.includes(
                                            item.value,
                                        ),
                                    )
                                }
                                onChange={(isSelected) => {
                                    schoolYearRowRefs.current.forEach((ref) => {
                                        ref.setIsChecked(isSelected);
                                    });
                                    dispatchState({
                                        input: "selectedSchoolYears",
                                        value: isSelected
                                            ? SCHOOL_YEARS.map(
                                                  (item) => item.value,
                                              )
                                            : [],
                                    });
                                }}
                                value="SELECT_ALL"
                            />
                            <Text fontSize="md">Select All</Text>
                        </HStack>
                        <ScrollView>
                            {SCHOOL_YEARS.map((item, index) => {
                                return (
                                    <MultiSelectRow
                                        key={
                                            item.value +
                                            "-" +
                                            state.values.selectedSchoolYears.indexOf(
                                                item.value,
                                            )
                                        }
                                        ref={(ref) => {
                                            if (
                                                ref &&
                                                !schoolYearRowRefs.current.get(
                                                    item.value,
                                                )
                                            ) {
                                                schoolYearRowRefs.current.set(
                                                    item.value,
                                                    ref,
                                                );
                                            }
                                        }}
                                        id={item.value}
                                        index={index}
                                        initiallySelected={
                                            state.values.selectedSchoolYears.indexOf(
                                                item.value,
                                            ) !== -1
                                        }
                                        itemPressHandler={
                                            schoolYearPressHandler
                                        }
                                        label={item.label}
                                    />
                                );
                            })}
                        </ScrollView>
                    </Box>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isDisabled={state.values.selectedSchoolYears.length === 0}
                    mb="4"
                    mt="8"
                    onPress={() => {
                        dispatchState({
                            input: "hasSelectedAllSchoolYears",
                            value:
                                state.values.selectedSchoolYears.length ===
                                SCHOOL_YEARS.length,
                        });
                        schoolYearRowRefs.current = new Map();
                        setCurrentPageIndex(
                            (currentPageIndex) => currentPageIndex + 1,
                        );
                    }}
                    px="6">
                    Continue
                </ButtonDebounced>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        schoolYearPressHandler,
        state.values.selectedSchoolYears,
        state.values.selectedSchoolYears.length,
    ]);

    const renderOtherOptions = useMemo(() => {
        return (
            <>
                <VStack alignItems="center" flex={1} space="8" width="85%">
                    <VStack alignItems="center" space="4">
                        <Text fontSize="lg">Exclude pupils who</Text>
                        <HStack space="3">
                            <Text color="surface.700" fontSize="md">
                                Have a book?
                            </Text>
                            <Checkbox
                                defaultIsChecked={
                                    state.values.excludePupilsWhoHaveBook
                                }
                                onChange={(isSelected) =>
                                    dispatchState({
                                        input: "excludePupilsWhoHaveBook",
                                        value: isSelected,
                                    })
                                }
                                size="md"
                                value="HAVE_BOOK"
                            />
                        </HStack>
                        <HStack space="3">
                            <Text color="surface.700" fontSize="md">
                                Have an instrument?
                            </Text>
                            <Checkbox
                                defaultIsChecked={
                                    state.values.excludePupilsWhoHaveInstrument
                                }
                                onChange={(isSelected) =>
                                    dispatchState({
                                        input: "excludePupilsWhoHaveInstrument",
                                        value: isSelected,
                                    })
                                }
                                size="md"
                                value="HAVE_INSTRUMENT"
                            />
                        </HStack>
                        <HStack space="3">
                            <Text color="surface.700" fontSize="md">
                                Have an instrument supplied by us?
                            </Text>
                            <Checkbox
                                defaultIsChecked={
                                    state.values
                                        .excludePupilsWhoHaveInstrumentSuppliedByUs
                                }
                                onChange={(isSelected) =>
                                    dispatchState({
                                        input: "excludePupilsWhoHaveInstrumentSuppliedByUs",
                                        value: isSelected,
                                    })
                                }
                                size="md"
                                value="HAVE_INSTRUMENT_SUPPLIED_BY_US"
                            />
                        </HStack>
                        <HStack mb="2" space="3">
                            <Text color="surface.700" fontSize="md">
                                Have returned their instrument?
                            </Text>
                            <Checkbox
                                defaultIsChecked={
                                    state.values
                                        .excludePupilsWhoHaveReturnedInstrument
                                }
                                onChange={(isSelected) =>
                                    dispatchState({
                                        input: "excludePupilsWhoHaveReturnedInstrument",
                                        value: isSelected,
                                    })
                                }
                                size="md"
                                value="HAVE_RETURNED_INSTRUMENT"
                            />
                        </HStack>
                    </VStack>
                    <VStack alignItems="center" space="4">
                        <Text fontSize="lg">Send by</Text>
                        <Select
                            bg="surface.100"
                            color="surface.900"
                            fontSize="md"
                            onValueChange={(itemValue) =>
                                dispatchState({
                                    input: "sendType",
                                    value: itemValue,
                                })
                            }
                            selectedValue={state.values.sendType}
                            textAlign="center"
                            width="250px">
                            <Select.Item
                                key={"EMAIL_SMS"}
                                actionSheetLabel={"Email with SMS fallback"}
                                value={"EMAIL_SMS"}
                            />
                            <Select.Item
                                key={"EMAIL"}
                                actionSheetLabel={"Email only"}
                                value={"EMAIL"}
                            />
                            <Select.Item
                                key={"SMS_EMAIL"}
                                actionSheetLabel={"SMS with email fallback"}
                                value={"SMS_EMAIL"}
                            />
                            <Select.Item
                                key={"SMS"}
                                actionSheetLabel={"SMS only"}
                                value={"SMS"}
                            />
                        </Select>
                    </VStack>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    mb="2"
                    mt="92px"
                    onPress={() =>
                        setCurrentPageIndex(
                            (currentPageIndex) => currentPageIndex + 1,
                        )
                    }
                    px="6">
                    Continue
                </ButtonDebounced>
            </>
        );
    }, [
        state.values.excludePupilsWhoHaveReturnedInstrument,
        state.values.excludePupilsWhoHaveBook,
        state.values.excludePupilsWhoHaveInstrument,
        state.values.excludePupilsWhoHaveInstrumentSuppliedByUs,
        state.values.sendType,
    ]);

    const renderMessageInput = useMemo(() => {
        return (
            <>
                <VStack alignItems="center" flex={1} space="6" width="95%">
                    <Text color="surface.600" mx="6" textAlign="center">
                        {`You can use {{firstName}}, {{fullName}} and {{accountNumber}} to personalise the message${
                            state.values.sendType !== "SMS"
                                ? " and/or subject"
                                : ""
                        }.`}
                    </Text>
                    {state.values.sendType !== "SMS" ? (
                        <TextInput
                            ref={messageSubjectInputRef}
                            _focus={{ bg: "white" }}
                            _hover={{ bg: "white" }}
                            autoCapitalize="none"
                            bg="white"
                            borderRadius="xl"
                            fontFamily="Poppins-Regular"
                            id="messageSubject"
                            initiallyValid
                            initialValue={state.values.messageSubject}
                            keyboardType="default"
                            label={`Subject${
                                state.values.sendType === "SMS_EMAIL"
                                    ? " (for fallback)"
                                    : ""
                            }`}
                            labelStyle={{
                                color: "surface.700",
                                fontSize: "sm",
                            }}
                            onFinishEditing={messageFinishEditingHandler}
                            onSubmit={() =>
                                messageStringInputRef.current?.focus()
                            }
                            py="1.5"
                            size="lg"
                        />
                    ) : null}
                    <TextInput
                        ref={messageStringInputRef}
                        _focus={{ bg: "white" }}
                        _hover={{ bg: "white" }}
                        autoCapitalize="sentences"
                        bg="white"
                        borderRadius="xl"
                        fontFamily="Poppins-Regular"
                        height="200px"
                        id="messageString"
                        initialValue={state.values.messageString}
                        keyboardType="default"
                        label="Message"
                        labelStyle={{
                            color: "surface.700",
                            fontSize: "sm",
                        }}
                        multiline
                        onFinishEditing={messageFinishEditingHandler}
                        size="lg"
                    />
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isDisabled={
                        !state.values.messageString ||
                        (!state.values.messageSubject &&
                            state.values.sendType !== "SMS")
                    }
                    mb="4"
                    mt="10"
                    onPress={() =>
                        setCurrentPageIndex(
                            (currentPageIndex) => (currentPageIndex += 1),
                        )
                    }
                    px="6">
                    Preview
                </ButtonDebounced>
            </>
        );
    }, [
        messageFinishEditingHandler,
        state.values.messageString,
        state.values.messageSubject,
        state.values.sendType,
    ]);

    const renderMessagePreview = useMemo(() => {
        const selectedSchoolsText = state.values.hasSelectedAllSchools
            ? "- At all schools"
            : `- At ${listWithAndConverter(
                  listToStringWithCommasConverter(
                      state.values.selectedSchoolNames,
                  ),
              )}`;
        const selectedTeachersText = state.values.hasSelectedAllTeachers
            ? "- Taught by any teacher"
            : `- Taught by ${listWithAndConverter(
                  listToStringWithCommasConverter(
                      state.values.selectedTeacherNames,
                  ),
              )}`;
        const selectedSchoolYearsText = state.values.hasSelectedAllSchoolYears
            ? "- In all year groups"
            : `- In ${listWithAndConverter(
                  listToStringWithCommasConverter(
                      state.values.selectedSchoolYears
                          .sort((a, b) => parseInt(a) - parseInt(b))
                          .map((item) => schoolYearConverter(parseInt(item))),
                  ),
              )}`;

        const otherFilterText = `- Excluding those who ${listWithAndConverter(
            listToStringWithCommasConverter(
                [
                    state.values.excludePupilsWhoHaveBook && "have a book",
                    state.values.excludePupilsWhoHaveInstrument &&
                        "have an instrument",
                    state.values.excludePupilsWhoHaveInstrumentSuppliedByUs &&
                        "have an instrument supplied by us",
                    state.values.excludePupilsWhoHaveReturnedInstrument &&
                        "have returned their instrument",
                ].filter(Boolean) as string[],
            ),
        )}`;

        return (
            <>
                <VStack alignItems="center" flex={1} space="6" width="95%">
                    {state.values.sendType !== "SMS" ? (
                        <VStack space="1" width="100%">
                            <Text color="surface.700">Subject</Text>
                            <Text
                                borderColor="surface.200"
                                borderRadius="xl"
                                borderWidth={1}
                                fontFamily="Poppins-Regular"
                                fontSize="md"
                                mx="-1"
                                px="2"
                                py="1.5">
                                {messagePreviewConverter(
                                    state.values.messageSubject,
                                )}
                            </Text>
                        </VStack>
                    ) : null}
                    <VStack space="1" width="100%">
                        <Text color="surface.700">Message</Text>
                        <ScrollView
                            borderColor="surface.200"
                            borderRadius="xl"
                            borderWidth={1}
                            height="150px"
                            mx="-1"
                            px="2"
                            py="1.5">
                            <Text fontFamily="Poppins-Regular" fontSize="md">
                                {messagePreviewConverter(
                                    state.values.messageString,
                                )}
                            </Text>
                        </ScrollView>
                    </VStack>
                    <VStack width="100%">
                        <Text
                            color="surface.700"
                            fontFamily="Poppins-Regular"
                            fontSize="md">{`Sending by ${sendTypeConverter(
                            state.values.sendType,
                        )} to active parents with children:`}</Text>
                        <Text
                            color="surface.700"
                            fontFamily="Poppins-Regular"
                            fontSize="md">
                            {selectedSchoolsText}
                        </Text>
                        <Text
                            color="surface.700"
                            fontFamily="Poppins-Regular"
                            fontSize="md">
                            {selectedTeachersText}
                        </Text>
                        <Text
                            color="surface.700"
                            fontFamily="Poppins-Regular"
                            fontSize="md">
                            {selectedSchoolYearsText}
                        </Text>
                        {state.values.excludePupilsWhoHaveBook ||
                        state.values.excludePupilsWhoHaveInstrument ||
                        state.values
                            .excludePupilsWhoHaveInstrumentSuppliedByUs ||
                        state.values.excludePupilsWhoHaveReturnedInstrument ? (
                            <Text
                                color="surface.700"
                                fontFamily="Poppins-Regular"
                                fontSize="md">
                                {otherFilterText}
                            </Text>
                        ) : null}
                    </VStack>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    mb="4"
                    mt="8"
                    onPress={() => {
                        sendBulkCommunication({
                            messageString: state.values.messageString,
                            messageSubject: state.values.messageSubject,
                            schoolIds: state.values.selectedSchoolIds,
                            teacherIds: !state.values.hasSelectedAllTeachers
                                ? state.values.selectedTeacherIds
                                : undefined,
                            schoolYears: state.values.selectedSchoolYears.map(
                                (item) => parseInt(item),
                            ),
                            excludePupilsWhoHaveBook:
                                state.values.excludePupilsWhoHaveBook,
                            excludePupilsWhoHaveInstrument:
                                state.values.excludePupilsWhoHaveInstrument,
                            excludePupilsWhoHaveInstrumentSuppliedByUs:
                                state.values
                                    .excludePupilsWhoHaveInstrumentSuppliedByUs,
                            excludePupilsWhoHaveReturnedInstrument:
                                state.values
                                    .excludePupilsWhoHaveReturnedInstrument,
                            sendType: state.values.sendType,
                        });
                        hideModal();
                    }}
                    px="6">
                    Send
                </ButtonDebounced>
            </>
        );
    }, [
        state.values.hasSelectedAllSchools,
        state.values.selectedSchoolNames,
        state.values.hasSelectedAllTeachers,
        state.values.selectedTeacherNames,
        state.values.hasSelectedAllSchoolYears,
        state.values.selectedSchoolYears,
        state.values.excludePupilsWhoHaveBook,
        state.values.excludePupilsWhoHaveInstrument,
        state.values.excludePupilsWhoHaveInstrumentSuppliedByUs,
        state.values.excludePupilsWhoHaveReturnedInstrument,
        state.values.sendType,
        state.values.messageSubject,
        state.values.messageString,
        state.values.selectedSchoolIds,
        state.values.selectedTeacherIds,
        sendBulkCommunication,
        hideModal,
    ]);

    return currentPageIndex === 0 ? (
        renderSelectSchools
    ) : currentPageIndex === 1 ? (
        renderSelectTeachers
    ) : (
        <Modal
            closeOnOverlayClick={false}
            isOpen={showModal}
            onClose={hideModal}
            size="xl">
            <Modal.Content height="620px" maxWidth="800px" width="800px">
                <Button
                    _hover={{ opacity: 0.8, bg: "transparent" }}
                    _pressed={{ opacity: 0.7, bg: "transparent" }}
                    bg="transparent"
                    left="3"
                    leftIcon={<ChevronLeftIcon ml="0" p="0" size="5" />}
                    onPress={() => {
                        setCurrentPageIndex((currentPageIndex) => {
                            const newPageIndex = currentPageIndex - 1;
                            if (newPageIndex === 2) {
                                // reset the school year row refs whenever we view it
                                schoolYearRowRefs.current = new Map();
                            }
                            return newPageIndex;
                        });
                    }}
                    p="1"
                    position="absolute"
                    top="4"
                    variant="ghost"
                    zIndex="1"
                />
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header>{`${
                    currentPageIndex === 2
                        ? "Select Year Groups for"
                        : currentPageIndex === 3
                          ? "Other Options for"
                          : currentPageIndex === 4
                            ? "Message for"
                            : "Preview of"
                } Bulk Email/SMS`}</Modal.Header>
                <Modal.Body
                    _scrollview={{
                        contentContainerStyle: {
                            justifyContent: "center",
                            flexGrow: 1,
                        },
                    }}
                    alignItems="center"
                    mb="4"
                    px="6">
                    {currentPageIndex === 2
                        ? renderSelectYearGroups
                        : currentPageIndex === 3
                          ? renderOtherOptions
                          : currentPageIndex === 4
                            ? renderMessageInput
                            : renderMessagePreview}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(SendBulkCommunicationModal);
