import React, {
    Suspense,
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
} from "react";
import type { FC, Dispatch } from "react";

import {
    Box,
    Text,
    VStack,
    Avatar,
    useToast,
    HStack,
    Center,
    Checkbox,
    Button,
} from "native-base";
import { useQueryLoader, usePreloadedQuery, useMutation } from "react-relay";
import type { PreloadedQuery } from "react-relay";
import type { Subscription } from "relay-runtime";

import { useData } from "pianofunclub-shared/providers/DataProvider";

import SideBar from "../../components/Drawers/SideBar";
import type {
    WaitingListStackNavigatorProps,
    WaitingListStackRouteProps,
} from "../../navigation/WaitingListNavigator";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { TrashIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    DeleteEnrolmentMutation,
    DeleteEnrolmentMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/DeleteEnrolmentMutation.graphql";
import type {
    EditEnrolmentMutation,
    EditEnrolmentMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/EditEnrolmentMutation.graphql";
import type { LoadEnrolmentQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolmentQuery.graphql";
import { delete_enrolment } from "pianofunclub-shared/relay/graphql/accounts/DeleteEnrolment";
import { edit_enrolment } from "pianofunclub-shared/relay/graphql/accounts/EditEnrolment";
import { load_enrolment } from "pianofunclub-shared/relay/graphql/accounts/LoadEnrolment";

import { INSTRUMENTS, SCHOOL_YEARS } from "pianofunclub-shared/utils/constants";
import {
    LESSON_STAGES,
    LESSON_TYPES,
} from "pianofunclub-shared/utils/constants";
import {
    formatDate,
    getInitials,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

export type NavigationProps = WaitingListStackNavigatorProps<"Enrolment">;
export type RouteProps = WaitingListStackRouteProps<"Enrolment">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadEnrolmentQueryReference: PreloadedQuery<
        LoadEnrolmentQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    route: RouteProps;
    state: State<ReducerValues>;
}

const SIDE_BAR_EXPANDED_WIDTH = 400;
const SIDE_BAR_COLLAPSED_WIDTH = 12;

export type ReducerValues = {
    clickedEnrolmentId?: string;
    contentIsRendered: boolean;
    dateTimePickerModalDate?: Date;
    dateTimePickerModalLessonDuration?: number;
    dateTimePickerModalLessonId?: string;
    dayIndex: number;
    deleteEnrolmentAlertIsOpen?: boolean;
    discountCategorySelect?: string;
    discountPercentage?: number;
    instrumentSelect?: string;
    lessonStageIndex: number;
    lessonTypeSelect?: string;
    offered?: boolean;
    paidBySchoolSelect?: boolean;
    premiumPupilSelect?: boolean;
    refreshHandler?: () => void;
    // don't handle state for most text inputs (mutation is fired on blur)
    // do for the selects so that they respond immeditately
    schoolSelect?: string;
    schoolYearSelect?: string;
    sentWaitingListEmail?: boolean;
    sideBarIsCollapsed: boolean;
    subscription?: Subscription;
};

export type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | Date
    | (() => void)
    | readonly (number | null)[]
    | undefined;

const EnrolmentContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadEnrolmentQueryReference,
        navigation,
        route,
        state,
    } = props;

    const enrolmentData = usePreloadedQuery(
        load_enrolment,
        loadEnrolmentQueryReference,
    );

    const toast = useToast();

    const { dataState } = useData();

    const allSchools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const commitEditEnrolment =
        useMutation<EditEnrolmentMutation>(edit_enrolment)[0];

    useEffect(() => {
        if (enrolmentData.enrolment) {
            // initialise local state
            // day index defaults to current day, but adjust if teacher
            // doesn't teach on current day
            dispatchState({
                input: "dayIndex",
                value: enrolmentData.enrolment.lessonDay ?? 0,
            });
            dispatchState({
                input: "schoolSelect",
                value: enrolmentData.enrolment.schoolName ?? undefined,
            });
            dispatchState({
                input: "schoolYearSelect",
                value:
                    enrolmentData.enrolment.schoolYear?.toString() ?? undefined,
            });
            dispatchState({
                input: "paidBySchoolSelect",
                value:
                    enrolmentData.enrolment.paidBySchool !== null
                        ? enrolmentData.enrolment.paidBySchool
                        : false,
            });
            dispatchState({
                input: "discountPercentage",
                value: enrolmentData.enrolment.discountPercentage ?? undefined,
            });
            dispatchState({
                input: "instrumentSelect",
                value: enrolmentData.enrolment.instrument ?? undefined,
            });
            dispatchState({
                input: "lessonStageIndex",
                value: enrolmentData.enrolment.lessonStage ?? undefined,
            });
            dispatchState({
                input: "lessonTypeSelect",
                value: enrolmentData.enrolment.lessonType ?? undefined,
            });
            dispatchState({
                input: "premiumPupilSelect",
                value: enrolmentData.enrolment.premiumPupil ?? undefined,
            });
            dispatchState({
                input: "offered",
                value: enrolmentData.enrolment.offered ?? undefined,
            });
            dispatchState({
                input: "sentWaitingListEmail",
                value:
                    enrolmentData.enrolment.sentWaitingListEmail ?? undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrolmentData.enrolment]);

    const editEnrolment = useCallback(
        (variables: {
            costPerLesson?: number;
            discountPercentage?: number;
            enrolmentId: string;
            instrument?: string;
            lessonBlockNotes?: string;
            lessonDay?: number;
            lessonDuration?: number;
            lessonStage?: string;
            lessonStartTime?: string;
            lessonType?: string;
            numberOfLessons?: number;
            offered?: boolean;
            paidBySchool?: boolean;
            parentEmail?: string;
            parentName?: string;
            parentPhoneNumber?: string;
            premiumPupil?: boolean;
            pupilFirstName?: string;
            pupilLastName?: string;
            pupilNotes?: string;
            schoolBorough?: string;
            schoolClass?: string;
            schoolName?: string;
            schoolPostcode?: string;
            schoolYear?: number;
            sentWaitingListEmail?: boolean;
            teacherEmail?: string;
        }) => {
            const editEnrolmentConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: EditEnrolmentMutation$data) => {
                    if (response?.editEnrolment?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Enrolment updated"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update enrolment"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitEditEnrolment(editEnrolmentConfig);
        },
        [commitEditEnrolment, toast],
    );

    const [commitDeleteEnrolment] =
        useMutation<DeleteEnrolmentMutation>(delete_enrolment);

    const deleteEnrolment = useCallback(
        (variables: { enrolmentId: string }, onComplete?: () => void) => {
            const deleteEnrolmentConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: DeleteEnrolmentMutation$data) => {
                    if (response?.deleteEnrolment?.success) {
                        navigation.navigate("WaitingListHub");
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Enrolment deleted"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't delete enrolment"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    onComplete?.();
                },
            };

            commitDeleteEnrolment(deleteEnrolmentConfig);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commitDeleteEnrolment],
    );

    const inputFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (
                isValid &&
                inputIdentifier &&
                typeof inputValue !== "undefined" &&
                // check if the value has changed before firing mutation
                // @ts-expect-error string indexing
                inputValue !== enrolmentData.enrolment?.[inputIdentifier] &&
                // @ts-expect-error string indexing
                inputValue !== enrolmentData.enrolment?.[inputIdentifier]
            ) {
                switch (inputIdentifier) {
                    case "discountPercentage":
                        // eslint-disable-next-line no-case-declarations
                        const parsedDiscount = parseInt(inputValue);
                        dispatchState({
                            input: "discountPercentage",
                            value: parsedDiscount,
                        });
                        if (parsedDiscount == 0) {
                            dispatchState({
                                input: "discountCategorySelect",
                                value: "FULL_PRICE",
                            });
                        }
                        editEnrolment({
                            enrolmentId: route.params.enrolmentId,
                            discountPercentage: parsedDiscount,
                        });
                        break;
                    case "costPerLesson": {
                        const parsedCost = inputValue.replace("£", "");
                        editEnrolment({
                            enrolmentId: route.params.enrolmentId,
                            costPerLesson: parseFloat(parsedCost),
                        });
                        break;
                    }
                    default:
                        editEnrolment({
                            enrolmentId: route.params.enrolmentId,
                            [inputIdentifier]: inputValue?.trim(),
                        });
                }
            }
        },
        [
            enrolmentData.enrolment,
            dispatchState,
            editEnrolment,
            route.params.enrolmentId,
        ],
    );

    const discountPercentageInputRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);

    const selectChangeHandler = useCallback(
        (inputIdentifier: string, itemValue: string | boolean) => {
            if (
                state.values?.[
                    `${inputIdentifier}Select` as keyof ReducerValues
                ] != itemValue
            ) {
                dispatchState({
                    input: `${inputIdentifier}Select`,
                    value: itemValue,
                });

                if (
                    inputIdentifier === "discountCategory" &&
                    typeof itemValue === "string"
                ) {
                    if (itemValue.includes("DISCOUNT")) {
                        dispatchState({
                            input: "discountPercentage",
                            value: 100,
                        });
                        discountPercentageInputRef.current?.setValue(
                            "100%",
                            true,
                        );
                    } else {
                        dispatchState({
                            input: "discountPercentage",
                            value: 0,
                        });
                        discountPercentageInputRef.current?.setValue(
                            "0%",
                            true,
                        );
                    }
                }
                editEnrolment({
                    enrolmentId: route.params.enrolmentId,
                    [inputIdentifier]: itemValue,
                });
            }
        },
        [state.values, dispatchState, editEnrolment, route.params.enrolmentId],
    );

    const renderSideBar = useMemo(() => {
        return (
            <>
                <SideBar
                    isCollapsed={state.values.sideBarIsCollapsed}
                    navigation={navigation}
                    setIsCollapsed={() =>
                        dispatchState({
                            input: "sideBarIsCollapsed",
                            value: !state.values.sideBarIsCollapsed,
                        })
                    }
                    width={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                >
                    <VStack
                        alignItems="center"
                        mt="20"
                        pb="6"
                        space="4"
                        width="100%"
                    >
                        <VStack alignItems="center">
                            <Avatar
                                _text={{ color: "surface.200" }}
                                bg="primary.600"
                                size="md"
                                source={{
                                    uri: undefined,
                                }}
                            >
                                {getInitials(
                                    enrolmentData.enrolment?.pupilFirstName ??
                                        "",
                                    enrolmentData.enrolment?.pupilLastName ??
                                        "",
                                )}
                            </Avatar>
                            <Text fontSize="xl" mt="2" textAlign="center">
                                {getFullName(
                                    enrolmentData.enrolment?.pupilFirstName ??
                                        "",
                                    enrolmentData.enrolment?.pupilLastName ??
                                        "",
                                )}
                            </Text>
                            <Text fontSize="lg">Enrolment</Text>
                            {enrolmentData.enrolment?.addedOn && (
                                <Text fontSize="lg">
                                    Added on:{" "}
                                    {formatDate(
                                        enrolmentData.enrolment?.addedOn,
                                    )}
                                </Text>
                            )}
                            <Button
                                _text={{ fontSize: "lg" }}
                                colorScheme="red"
                                leftIcon={
                                    <Center size="7">
                                        <TrashIcon color="#FFFFFF" size="6" />
                                    </Center>
                                }
                                marginTop={4}
                                onPress={() => {
                                    dispatchState({
                                        input: "deleteEnrolmentAlertIsOpen",
                                        value: true,
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        </VStack>
                        <VStack alignItems="center" space="2" width="100%">
                            <TextInput
                                autoCapitalize="words"
                                id="pupilFirstName"
                                initiallyValid={Boolean(
                                    enrolmentData.enrolment?.pupilFirstName,
                                )}
                                initialValue={
                                    enrolmentData.enrolment?.pupilFirstName ??
                                    ""
                                }
                                invalidIndicator
                                label="First name"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                onFinishEditing={inputFinishEditingHandler}
                                size="md"
                            />
                            <TextInput
                                autoCapitalize="words"
                                id="pupilLastName"
                                initiallyValid
                                initialValue={
                                    enrolmentData.enrolment?.pupilLastName ?? ""
                                }
                                invalidIndicator
                                label="Last name"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                onFinishEditing={inputFinishEditingHandler}
                                size="md"
                            />

                            <TextInput
                                id="positionInWaitingList"
                                initiallyValid
                                initialValue={
                                    enrolmentData.enrolment?.positionInWaitingList?.toString() ??
                                    ""
                                }
                                label="Position in Waiting List"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                maxLength={500}
                                onFinishEditing={inputFinishEditingHandler}
                                size="md"
                            />
                            <>
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        School
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "schoolName",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select school"
                                        selectedValue={
                                            state.values.schoolSelect
                                        }
                                    >
                                        {allSchools.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item?.node?.name}
                                                    actionSheetLabel={
                                                        titleCaseConverter(
                                                            item?.node?.name,
                                                        ) ?? ""
                                                    }
                                                    value={
                                                        item?.node?.name ?? ""
                                                    }
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        School Year
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "schoolYear",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select school year"
                                        selectedValue={
                                            state.values.schoolYearSelect
                                        }
                                    >
                                        {SCHOOL_YEARS.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.label}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>

                                <TextInput
                                    autoCapitalize="words"
                                    id="schoolClass"
                                    initiallyValid={Boolean(
                                        enrolmentData.enrolment?.schoolClass,
                                    )}
                                    initialValue={
                                        enrolmentData.enrolment?.schoolClass ??
                                        ""
                                    }
                                    invalidIndicator
                                    label="School Class"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />

                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        Instrument
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) =>
                                            selectChangeHandler(
                                                "instrument",
                                                itemValue,
                                            )
                                        }
                                        placeholder="Select instrument"
                                        selectedValue={
                                            state.values.instrumentSelect
                                        }
                                    >
                                        {INSTRUMENTS.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item?.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item?.value ?? ""}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                <TextInput
                                    autoCapitalize="words"
                                    id="parentName"
                                    initiallyValid={Boolean(
                                        enrolmentData.enrolment?.parentName,
                                    )}
                                    initialValue={
                                        enrolmentData.enrolment?.parentName ??
                                        ""
                                    }
                                    invalidIndicator
                                    label="Parent Name"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <TextInput
                                    autoCapitalize="words"
                                    id="parentEmail"
                                    initiallyValid={Boolean(
                                        enrolmentData.enrolment?.parentEmail,
                                    )}
                                    initialValue={
                                        enrolmentData.enrolment?.parentEmail ??
                                        ""
                                    }
                                    invalidIndicator
                                    label="Parent Email"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <TextInput
                                    autoCapitalize="words"
                                    id="parentPhoneNumber"
                                    initiallyValid={Boolean(
                                        enrolmentData.enrolment
                                            ?.parentPhoneNumber,
                                    )}
                                    initialValue={
                                        enrolmentData.enrolment
                                            ?.parentPhoneNumber ?? ""
                                    }
                                    invalidIndicator
                                    label="Parent Phone Number"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />
                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        Lesson Stage
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) => {
                                            dispatchState({
                                                input: "lessonStageIndex",
                                                value: itemValue,
                                            });
                                            selectChangeHandler(
                                                "lessonStage",
                                                itemValue,
                                            );
                                        }}
                                        placeholder="Select Lesson Stage"
                                        selectedValue={String(
                                            state.values.lessonStageIndex ?? "",
                                        )}
                                    >
                                        {LESSON_STAGES.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>

                                <VStack mt="1" space="0.5" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        Lesson Type
                                    </Text>
                                    <Select
                                        bg="surface.100"
                                        borderRadius="full"
                                        color="surface.900"
                                        fontSize="sm"
                                        onValueChange={(itemValue) => {
                                            dispatchState({
                                                input: "lessonTypeSelect",
                                                value: itemValue,
                                            });
                                            selectChangeHandler(
                                                "lessonType",
                                                itemValue,
                                            );
                                        }}
                                        placeholder="Select Lesson Type"
                                        selectedValue={
                                            state.values.lessonTypeSelect
                                        }
                                    >
                                        {LESSON_TYPES.map((item) => {
                                            return (
                                                <Select.Item
                                                    key={item.value}
                                                    actionSheetLabel={
                                                        item.label
                                                    }
                                                    value={item.value}
                                                />
                                            );
                                        })}
                                    </Select>
                                </VStack>
                                <TextInput
                                    autoCapitalize="sentences"
                                    id="pupilNotes"
                                    initiallyValid
                                    initialValue={
                                        enrolmentData.enrolment?.pupilNotes ??
                                        ""
                                    }
                                    label="Pupil Notes"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={500}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />

                                <TextInput
                                    autoCapitalize="sentences"
                                    id="adminNotes"
                                    initiallyValid
                                    initialValue={
                                        enrolmentData.enrolment?.adminNotes ??
                                        ""
                                    }
                                    label="Admin Notes"
                                    labelStyle={{
                                        color: "surface.700",
                                        fontSize: "sm",
                                    }}
                                    maxLength={500}
                                    onFinishEditing={inputFinishEditingHandler}
                                    size="md"
                                />

                                <HStack mt="1" space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        Offered?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            enrolmentData.enrolment?.offered ??
                                            false
                                        }
                                        onChange={(isSelected) => {
                                            selectChangeHandler(
                                                "offered",
                                                isSelected,
                                            );
                                        }}
                                        size="md"
                                        value="OFFERED"
                                    />
                                </HStack>

                                {enrolmentData.enrolment?.offered &&
                                    enrolmentData.enrolment?.offeredOn && (
                                        <HStack mt="1" space="3" width="100%">
                                            <Text color="" fontSize="sm" pl="1">
                                                Offered On:{" "}
                                                {formatDate(
                                                    enrolmentData.enrolment
                                                        ?.offeredOn,
                                                )}
                                            </Text>
                                        </HStack>
                                    )}

                                <HStack mt="1" space="3" width="100%">
                                    <Text
                                        color="surface.700"
                                        fontSize="sm"
                                        pl="1"
                                    >
                                        Sent Waiting List Email?
                                    </Text>
                                    <Checkbox
                                        defaultIsChecked={
                                            enrolmentData.enrolment
                                                ?.sentWaitingListEmail ?? false
                                        }
                                        onChange={(isSelected) => {
                                            selectChangeHandler(
                                                "sentWaitingListEmail",
                                                isSelected,
                                            );
                                        }}
                                        size="md"
                                        value="SENT_WAITING_LIST_EMAIL"
                                    />
                                </HStack>
                            </>
                        </VStack>
                    </VStack>
                </SideBar>

                <AlertPopup
                    alertIsOpen={Boolean(
                        state.values.deleteEnrolmentAlertIsOpen,
                    )}
                    body="This cannot be undone and all their data will be permanently lost."
                    header={
                        "Are you sure you want to delete this enrolment from the Waiting List?"
                    }
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "deleteEnrolmentAlertIsOpen",
                            value: isOpen,
                        })
                    }
                >
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="muted"
                        minWidth="100"
                        onPress={() => {
                            dispatchState({
                                input: "deleteEnrolmentAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%"
                    >
                        No, go back
                    </Button>
                    <ButtonDebounced
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="100"
                        onPress={() => {
                            if (enrolmentData.enrolment?.id) {
                                deleteEnrolment({
                                    enrolmentId: enrolmentData.enrolment.id,
                                });
                            }
                            dispatchState({
                                input: "deleteEnrolmentAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="40%"
                    >
                        Yes, delete
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        state.values.sideBarIsCollapsed,
        state.values.schoolSelect,
        state.values.schoolYearSelect,
        state.values.instrumentSelect,
        state.values.lessonStageIndex,
        state.values.lessonTypeSelect,
        state.values.deleteEnrolmentAlertIsOpen,
        navigation,
        enrolmentData.enrolment?.pupilFirstName,
        enrolmentData.enrolment?.pupilLastName,
        enrolmentData.enrolment?.addedOn,
        enrolmentData.enrolment?.positionInWaitingList,
        enrolmentData.enrolment?.schoolClass,
        enrolmentData.enrolment?.parentName,
        enrolmentData.enrolment?.parentEmail,
        enrolmentData.enrolment?.parentPhoneNumber,
        enrolmentData.enrolment?.pupilNotes,
        enrolmentData.enrolment?.adminNotes,
        enrolmentData.enrolment?.offered,
        enrolmentData.enrolment?.offeredOn,
        enrolmentData.enrolment?.sentWaitingListEmail,
        enrolmentData.enrolment?.id,
        inputFinishEditingHandler,
        allSchools,
        dispatchState,
        selectChangeHandler,
        deleteEnrolment,
    ]);

    return (
        <Box flex={1}>
            {renderSideBar}
            <Box
                flex={1}
                flexGrow={1}
                ml={
                    state.values.sideBarIsCollapsed
                        ? SIDE_BAR_COLLAPSED_WIDTH
                        : SIDE_BAR_EXPANDED_WIDTH
                }
                mt="70"
                pt="6"
                px="6"
            ></Box>
        </Box>
    );
};

const EnrolmentScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const initialState = useMemo(() => {
        // select the current day on the teacher register tab
        return {
            values: {
                isRefetching: false,
                contentIsRendered: false,
                subscription: undefined,
                sideBarIsCollapsed: false,
                schoolSelect: undefined,
                schoolYearSelect: undefined,
                paidBySchoolSelect: undefined,
                discountCategorySelect: undefined,
                discountPercentage: undefined,
                refreshHandler: undefined,
                clickedAccountId: undefined,
                dayIndex: 0,
                lessonStageIndex: 0,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadEnrolmentQueryReference, loadEnrolmentQuery] =
        useQueryLoader<LoadEnrolmentQuery>(load_enrolment);

    useEffect(() => {
        loadEnrolmentQuery(
            {
                enrolmentId: route.params.enrolmentId,
            },
            { fetchPolicy: "store-or-network" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadEnrolmentQueryReference]);

    const renderLoadingFallback = useMemo(() => {
        return (
            <HStack flex={1}>
                <SideBar
                    isCollapsed={state.values.sideBarIsCollapsed}
                    navigation={navigation}
                    position="relative"
                    setIsCollapsed={() =>
                        dispatchState({
                            input: "sideBarIsCollapsed",
                            value: !state.values.sideBarIsCollapsed,
                        })
                    }
                    top="0"
                    width={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                />
                <LoadingBlobs
                    ml={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH + 1
                            : SIDE_BAR_EXPANDED_WIDTH + 1
                    }
                    pt="70"
                >
                    Loading Enrolment...
                </LoadingBlobs>
            </HStack>
        );
    }, [navigation, state.values.sideBarIsCollapsed]);

    return (
        <Box bg="surface.100" flex={1}>
            {loadEnrolmentQueryReference != null ? (
                <Suspense fallback={renderLoadingFallback}>
                    <EnrolmentContent
                        dispatchState={dispatchState}
                        loadEnrolmentQueryReference={
                            loadEnrolmentQueryReference
                        }
                        navigation={navigation}
                        route={route}
                        state={state}
                    />
                </Suspense>
            ) : (
                renderLoadingFallback
            )}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Enrolment",
};

export default EnrolmentScreen;
