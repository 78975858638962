import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import AccountScreen, {
    screenOptions as accountScreenOptions,
} from "../screens/accounts/AccountScreen";
import InvoiceScreen, {
    screenOptions as invoiceScreenOptions,
} from "../screens/invoicing/InvoiceScreen";
import DefaultTermDatesScreen, {
    screenOptions as defaultTermDatesScreenOptions,
} from "../screens/schools/DefaultTermDatesScreen";
import SchoolScreen, {
    screenOptions as schoolScreenOptions,
} from "../screens/schools/SchoolScreen";
import SchoolsHubScreen, {
    screenOptions as schoolsHubScreenOptions,
} from "../screens/schools/SchoolsHubScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type {
    SchoolParams,
    AccountParams,
    DefaultTermDatesParams,
    InvoiceParams,
} from "./utils/ScreenParams";

export type SchoolsStackNavigatorParamList = {
    Account: AccountParams;
    DefaultTermDates: DefaultTermDatesParams;
    Invoice: InvoiceParams;
    School: SchoolParams;
    SchoolsHub?: {
        orderBy?: string;
        searchTerm?: string;
        status?: string;
    };
};

type NavigatorProps<T extends keyof SchoolsStackNavigatorParamList> =
    StackNavigationProp<SchoolsStackNavigatorParamList, T>;

export type SchoolsStackNavigatorProps<
    T extends keyof SchoolsStackNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"SchoolsHubTab">
>;

export type SchoolsStackRouteProps<
    T extends keyof SchoolsStackNavigatorParamList,
> = RouteProp<SchoolsStackNavigatorParamList, T>;

const SchoolsStackNavigator =
    createStackNavigator<SchoolsStackNavigatorParamList>();

const SchoolsNavigator = (): ReactElement => {
    return (
        <SchoolsStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <SchoolsStackNavigator.Screen
                component={SchoolsHubScreen}
                name="SchoolsHub"
                options={schoolsHubScreenOptions}
            />
            <SchoolsStackNavigator.Screen
                component={SchoolScreen}
                name="School"
                options={schoolScreenOptions}
            />
            <SchoolsStackNavigator.Screen
                component={AccountScreen}
                name="Account"
                options={accountScreenOptions}
            />
            <SchoolsStackNavigator.Screen
                component={InvoiceScreen}
                name="Invoice"
                options={invoiceScreenOptions}
            />
            <SchoolsStackNavigator.Screen
                component={DefaultTermDatesScreen}
                name="DefaultTermDates"
                options={defaultTermDatesScreenOptions}
            />
        </SchoolsStackNavigator.Navigator>
    );
};

export default SchoolsNavigator;
