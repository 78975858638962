import { graphql } from "react-relay";

export const edit_school = graphql`
    mutation EditSchoolMutation(
        $input: EditSchoolInput!
        $connections: [ID!]!
    ) {
        editSchool(input: $input) {
            success
            errors
            deletedSchoolId @deleteEdge(connections: $connections)
            school {
                id
                name
                archived
                schoolType
                headTeacher {
                    id
                    phoneNumber
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
                mainOfficeContact {
                    id
                    phoneNumber
                    addressFirstLine
                    addressSecondLine
                    city
                    postcode
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                }
                otherContacts {
                    edges {
                        node {
                            id
                            phoneNumber
                            label
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                }
            }
        }
    }
`;
