// these utility functions convert back-end data / data in unhelpful formats into usable formats
import "intl";
import "intl/locale-data/jsonp/en";
import React from "react";
import type { ReactElement } from "react";

import { format } from "date-fns";
import { Text } from "native-base";

import { MONTHS, TEACHING_DAYS } from "./constants";

export const numberConverter = (input_number: number): string => {
    // convert a large number to a nice format (e.g. 12321 -> 12.3k)
    if (input_number < 1000) {
        return input_number.toString();
    } else if (input_number < 1000000) {
        return (input_number / 1000).toFixed(1).toString() + "k";
    } else if (input_number < 1000000000) {
        return (input_number / 1000000).toFixed(1).toString() + "m";
    } else {
        // lol - here's hoping we get billions of likes
        return (input_number / 1000000000).toFixed(1).toString() + "b";
    }
};

export const secondsConverter = (
    hours: number,
    minutes: number,
    seconds: number,
): number => {
    return (hours * 60 + minutes) * 60 + seconds;
};

export const padWithZero = (value: number): string => {
    if (value < 10) {
        return "0" + value;
    } else {
        return String(value);
    }
};

export const durationConverter = (
    durationInSeconds: number,
    showSeconds: boolean,
): string => {
    // convert duration to XX hr XX min XX sec (or just XX mins)
    let duration = durationInSeconds;
    const hours = Math.floor(duration / 3600);
    duration %= 3600;
    const minutes = Math.floor(duration / 60);
    duration %= 60;
    let output = "";
    if (hours > 0) {
        output += hours + " hr ";
    }
    if (minutes > 0) {
        output += minutes + " min";
    }
    if (showSeconds) {
        output += duration + " sec";
    }
    return output.trim();
};

export const durationInputConverter = (
    durationInSeconds?: number,
): Date | undefined => {
    // convert duration in seconds to Date for DurationInput component
    if (durationInSeconds) {
        let duration = durationInSeconds;
        const hours = Math.floor(duration / 3600);
        duration %= 3600;
        const minutes = Math.floor(duration / 60);
        duration %= 60;
        if (hours < 23) {
            return new Date(`01/01/1970 ${hours}:${minutes}:${duration}`);
        } else {
            return new Date(`02/01/1970 ${23}:${minutes}:${duration}`);
        }
    } else {
        return;
    }
};

export const reverseDurationInputConverter = (
    durationString?: string,
    defaultLargeUnit?: "hours" | "minutes",
): number | undefined => {
    // convert "(XX:)XX:XX AM" to duration in seconds for DurationInput component
    // defaultLargeUnit determines whether XX:XX is hh:mm or mm:ss
    if (typeof durationString === "string") {
        if (durationString.endsWith("AM")) {
            durationString = durationString.slice(0, -3); // remove meridian
        }
        let seconds = 0;
        if (durationString.length === 4 || durationString.length === 7) {
            // pad with a zero if 4:30:10 or 4:30
            durationString = "0" + durationString;
        }
        // pad all durations to XX:XX:XX based on defaultLargeUnit
        if (
            durationString.length === 5 &&
            (defaultLargeUnit ?? "hours") === "hours"
        ) {
            durationString += ":00";
        } else if (
            durationString.length === 5 &&
            defaultLargeUnit === "minutes"
        ) {
            durationString = "00:" + durationString;
        }
        seconds += 3600 * parseInt(durationString.slice(0, 2));
        seconds += 60 * parseInt(durationString.slice(3, 5));
        seconds += parseInt(durationString.slice(6));
        return seconds;
    } else {
        return;
    }
};

export const shortDurationConverter = (durationInSeconds: number): string => {
    // convert duration to XXmXXs (or just XX mins)
    let seconds = durationInSeconds;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    let output = "";
    if (minutes > 0) {
        if (seconds !== 0) {
            output += minutes + ":";
        } else {
            output += minutes + " m";
        }
    }
    if (seconds > 0) {
        output += seconds + " s";
    }
    return output.trim();
};

export const timestampConverter = (
    timestamp: string,
    short?: boolean,
): string | undefined => {
    // convert a timestamp into a nice-looking relative date
    const parsedTimestamp = new Date(timestamp);
    const diff = (new Date().getTime() - parsedTimestamp.getTime()) / 1000;
    const day_diff = Math.floor(diff / 86400);
    const date = parsedTimestamp.toDateString().slice(4);
    const time = parsedTimestamp.toTimeString().substr(0, 5);

    // this catches things created very recently that are
    // marginally judged to be future dates
    if (day_diff === -1 && diff > -1) return "just now";
    // if the day is in the future, give the date and time
    if (isNaN(day_diff) || day_diff < 0) return date + " at " + time;
    // otherwise give a relative date / time
    const output =
        (day_diff === 0 &&
            ((diff < 60 && "just now") ||
                (diff < 120 && "1 minute ago") ||
                (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
                (diff < 7200 && "1 hour ago") ||
                (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
        (day_diff === 1 && "yesterday" + (!short ? " at " + time : "")) ||
        (day_diff < 7 &&
            day_diff + " days ago" + (!short ? " at " + time : "")) ||
        (day_diff < 14 && "1 week ago" + (!short ? " at " + time : "")) ||
        (day_diff < 30 &&
            Math.ceil(day_diff / 7) +
                " weeks ago" +
                (!short ? " at " + time : "")) ||
        (day_diff < 60 && "1 month ago" + (!short ? " at " + time : "")) ||
        (day_diff < 365 &&
            Math.ceil(day_diff / 30) +
                " months ago" +
                (!short ? " at " + time : "")) ||
        (day_diff < 730 && "1 year ago" + (!short ? " at " + time : "")) ||
        Math.ceil(day_diff / 365) +
            " years ago" +
            (!short ? " at " + time : "");
    return output;
};

export const notificationTimestampConverter = (
    timestamp: string,
): string | false => {
    // convert a timestamp into a nice-looking relative date
    const parsedTimestamp = new Date(timestamp);
    const diff = (new Date().getTime() - parsedTimestamp.getTime()) / 1000;
    const day_diff = Math.floor(diff / 86400);
    const date = parsedTimestamp.toDateString().slice(4);

    // if the day is in the future, give the date
    if (isNaN(day_diff) || day_diff < 0) return date;
    // otherwise give a relative date / time
    const output =
        (day_diff === 0 &&
            ((diff < 60 && "just now") ||
                (diff < 120 && "1 min") ||
                (diff < 3600 && Math.floor(diff / 60) + " mins") ||
                (diff < 7200 && "1 hr") ||
                (diff < 86400 && Math.floor(diff / 3600) + " hrs"))) ||
        (day_diff === 1 && "1 day") ||
        (day_diff < 7 && day_diff + " days") ||
        (day_diff < 14 && "1 week") ||
        (day_diff < 30 && Math.ceil(day_diff / 7) + " weeks") ||
        (day_diff < 60 && "1 month") ||
        (day_diff < 365 && Math.ceil(day_diff / 30) + " months") ||
        (day_diff < 730 && "1 year") ||
        Math.ceil(day_diff / 365) + " years";
    return output;
};

export const listToStringWithCommasConverter = (array: string[]): string => {
    if (array.length === 0) {
        return "";
    }

    if (array.length === 1) {
        return array[0];
    }

    return array.slice(0, -1).join(", ") + ", " + array.slice(-1);
};

// overload fn so that string input = string output and anything else = undefined output
export function listWithAndConverter(text: string): string;
// eslint-disable-next-line no-redeclare
export function listWithAndConverter<T>(text: T): undefined;
// eslint-disable-next-line no-redeclare
export function listWithAndConverter<T>(text: T): string | undefined {
    if (typeof text === "string") {
        return text.replace(/,(?!\s+and\b)(?=[^,]+$)/, " &");
    } else {
        return undefined;
    }
}

export const titleCaseConverter = (
    text: string | null | undefined,
): string | undefined => {
    // used to convert text in any case to Title Case (should normally be done using
    // text-transform: capitalize rather than modifying the string with this fn.
    if (text) {
        return text
            .replace(/_/g, " ")
            .replace(
                /(^\w|\s\w)(\S*)/g,
                (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase(),
            );
    } else {
        return;
    }
};

export const dateStringConverter = (dateString: string): string => {
    // switch ddMMyyyy format to yyyy-MM-dd
    if (dateString.length === 8) {
        return `${dateString.slice(4)}-${dateString.slice(
            2,
            4,
        )}-${dateString.slice(0, 2)}`;
    } else {
        return "";
    }
};

export const inputDecimalConverter = (
    value: string,
    decimalPlaces: number,
    maxLength: number,
): string => {
    const convertedValue = parseFloat(value)
        .toFixed(decimalPlaces)
        .toString()
        .slice(0, maxLength);
    if (convertedValue.endsWith(".")) {
        return convertedValue.slice(0, -1);
    } else {
        return convertedValue;
    }
};

export const lessonConverter = (
    lesson?: number,
): { lesson?: number; term?: number } => {
    if (lesson) {
        return { lesson: lesson % 10, term: Math.floor(lesson / 10) + 1 };
    } else {
        return { lesson: undefined, term: undefined };
    }
};

export const reverseLessonConverter = (
    term: string,
    lesson: string,
): number => {
    return (parseInt(term) - 1) * 10 + parseInt(lesson);
};

export const roundToDecimalPlace = (
    input: number,
    number_of_decimal_places: number,
): number => {
    return (
        Math.round((input + Number.EPSILON) * 10 ** number_of_decimal_places) /
        10 ** number_of_decimal_places
    );
};

export const formatDate = (
    timestamp?: string,
    includeBrackets?: boolean,
): string => {
    if (timestamp) {
        if (includeBrackets) {
            return `(${format(new Date(timestamp), "d/MM/yyyy")})`;
        } else {
            return format(new Date(timestamp), "d/MM/yyyy");
        }
    } else {
        return "";
    }
};

export const getInitials = (firstName?: string, lastName?: string): string => {
    // remove special chars from both names
    const convertedFirstName = firstName?.replace(/[^a-zA-Z0-9 -]/g, "");
    const convertedLastName = lastName?.replace(/[^a-zA-Z0-9 -]/g, "");
    if (convertedFirstName && convertedLastName) {
        return (
            convertedFirstName[0] +
            convertedLastName
                .split(/\s/)
                .reduce((response, word, currentIndex) => {
                    if (currentIndex <= 1) {
                        response += word.slice(0, 1);
                    }
                    return response;
                }, "")
        );
    } else if (convertedFirstName) {
        return convertedFirstName[0];
    } else {
        return "";
    }
};

export const schoolYearConverter = (
    schoolYear?: number | null,
    options?: {
        currentStartingYear?: number;
        selectedStartingYear?: number;
        short?: boolean;
    },
): string => {
    const { currentStartingYear, selectedStartingYear, short } = options ?? {};

    if (typeof schoolYear === "number") {
        let relativeSchoolYear = schoolYear;
        if (
            selectedStartingYear !== undefined &&
            currentStartingYear !== undefined
        ) {
            relativeSchoolYear =
                schoolYear - (currentStartingYear - selectedStartingYear);
        }

        if (relativeSchoolYear === -2) {
            return "Other";
        } else if (relativeSchoolYear === -1) {
            return short ? "Nur" : "Nursery";
        } else if (relativeSchoolYear === 0) {
            return short ? "Rec" : "Reception";
        } else if (relativeSchoolYear <= -2) {
            return ""; // invalid school year
        } else if (relativeSchoolYear > 13) {
            return short ? "13+" : "Finished School";
        } else {
            return short
                ? relativeSchoolYear.toString()
                : `Year ${relativeSchoolYear}`;
        }
    } else {
        return "";
    }
};

export const schoolTypeConverter = (schoolType: string | null | undefined) => {
    if (schoolType === "PRIMARY") {
        return "Primary";
    } else if (schoolType === "SECONDARY") {
        return "Secondary";
    } else if (schoolType === "PRIMARY_AND_SECONDARY") {
        return "Both";
    } else {
        return "";
    }
};

// splits up an array into chunks
export const getArrayAsChunks = <T,>(array: T[], chunkSize: number): T[][] => {
    const result = [];
    const data = array.slice(0);
    while (data[0]) {
        result.push(data.splice(0, chunkSize));
    }
    return result;
};

const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "GBP",
});

export const formatCurrency = (amount?: number | string | null): string => {
    if (typeof amount === "number") {
        return formatter.format(amount);
    } else if (typeof amount === "string" && isNumeric(amount)) {
        return formatter.format(parseFloat(amount));
    } else {
        return "";
    }
};

export const formatPercentage = (
    value?: number | null,
    isFraction?: boolean,
): string => {
    let parsedValue = value;
    if (typeof value === "string" && isNumeric(value)) {
        parsedValue = parseFloat(value);
    }
    if (typeof parsedValue === "number") {
        return `${(parsedValue * (isFraction ? 100 : 1)).toFixed(0)}%`;
    } else {
        return "";
    }
};

export const getLineItemType = (
    lineItemIndex: number,
    pupilId?: string,
): string => {
    switch (lineItemIndex) {
        case 0:
            return "MISSED_LESSON_DISCOUNT";
        case 1:
            return "EXAM_FEE";
        default:
            if (pupilId) {
                return "BLOCK_OTHER_FEE_OR_DISCOUNT";
            } else {
                return "ACCOUNT_OTHER_FEE_OR_DISCOUNT";
            }
    }
};

export const getNumberWithOrdinal = (number: number): string => {
    const suffix = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
};

// checks if a string is a valid number
export const isNumeric = (num: unknown): boolean =>
    (typeof num === "number" ||
        (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num as number);

export const combineAddress = (
    addressFirstLine?: string | null,
    addressSecondLine?: string | null,
    city?: string | null,
    postcode?: string | null,
): string => {
    return `${addressFirstLine ? addressFirstLine + "\n" : ""}${
        addressSecondLine ? addressSecondLine + "\n" : ""
    }${city ? city + "\n" : ""}${postcode ?? ""}`;
};

export const combineNotes = (
    teacherNote?: string | null,
    staffNote?: string | null,
    rearrangedTimestamp?: string | null,
): string => {
    return `${
        rearrangedTimestamp
            ? "Rearranged:\n" +
              format(new Date(rearrangedTimestamp), "H:mm do MMM")
            : ""
    }${rearrangedTimestamp && (teacherNote || staffNote) ? "\n" : ""}${
        teacherNote ? "Teacher:\n" + teacherNote : ""
    }${teacherNote && staffNote ? "\n\n" : ""}${
        staffNote ? "Staff:\n" + staffNote : ""
    }`;
};

export const combineWaitingListNotes = (
    pupilNotes?: string | null,
    adminNotes?: string | null,
    lessonBlockNotes?: string | null,
): string => {
    return `${
        pupilNotes ? "Pupil Notes:\n" + pupilNotes : ""
    }${pupilNotes && (adminNotes || lessonBlockNotes) ? "\n\n" : ""}${
        adminNotes ? "Admin Notes:\n" + adminNotes : ""
    }${(pupilNotes || adminNotes) && lessonBlockNotes ? "\n\n" : ""}${
        lessonBlockNotes ? "Staff:\n" + lessonBlockNotes : ""
    }`;
};

export const teachingDaysToString = (
    teachingDays?: readonly (number | null)[] | null,
): string => {
    let output = "";
    if (teachingDays) {
        output = "Teaching Days:\n";
        teachingDays.forEach((item, index) => {
            if (item !== null && TEACHING_DAYS?.[item]) {
                output +=
                    TEACHING_DAYS[item].label +
                    (index !== teachingDays.length - 1 ? "\n" : "");
            }
        });
    }
    return output;
};

export const discountTextConverter = (
    discountCategory?: string | null,
    discountPercentage?: number | null,
): string => {
    switch (discountCategory) {
        case "DISCOUNT_PUPIL_PPKID":
            return `PP kid (${discountPercentage}%)`;
        case "DISCOUNT_PUPIL_OTHER":
            return `Other (${discountPercentage}%)`;
        default:
            return "None";
    }
};

export const convertDayIndex = (dayIndex: number): number => {
    // convert django day (Mon = 0) to JS day (Sun = 0)
    const convertedDayIndex = dayIndex + 1;
    if (convertedDayIndex != 7) {
        return convertedDayIndex;
    } else {
        return 0;
    }
};

export const getScaledWindowDimension = (
    windowDimension: number,
    scale: number,
): number => {
    return (scale > 1 ? 1.25 : 1) * windowDimension;
};

export const sendTypeConverter = (sendType: string): string => {
    switch (sendType) {
        case "EMAIL_SMS":
            return "email (with SMS fallback)";
        case "EMAIL":
            return "email only";
        case "SMS_EMAIL":
            return "SMS (with email fallback)";
        case "SMS":
            return "SMS only";
        default:
            return "";
    }
};

export const messagePreviewConverter = (message: string): ReactElement[] => {
    const parts = message.split(
        /({{\s*firstName\s*}}|{{\s*fullName\s*}}|{{\s*accountNumber\s*}})/,
    );

    return parts.map((part, index) => {
        if (/{{\s*firstName\s*}}/.test(part)) {
            return (
                <Text key={index} fontStyle="italic">
                    John
                </Text>
            );
        }

        if (/{{\s*fullName\s*}}/.test(part)) {
            return (
                <Text key={index} fontStyle="italic">
                    John Smith
                </Text>
            );
        }

        if (/{{\s*accountNumber\s*}}/.test(part)) {
            return (
                <Text key={index} fontStyle="italic">
                    04712
                </Text>
            );
        }

        return <Text key={index}>{part}</Text>;
    });
};

// Returns the last day of the inputted month in dd/MM/yyyy format
export const monthFirstDay = (year: number, month: number): string => {
    const firstDay = new Date(year, month, 1);
    return format(firstDay, "dd-MM-yyyy");
};

// Returns the first inputted month in dd/MM/yyyy format
export const monthLastDay = (year: number, month: number): string => {
    const lastDay = new Date(year, month + 1, 0);
    return format(lastDay, "dd-MM-yyyy");
};

// Returns the date in a dd/MM/yyyy format
export const formattedDayMonthYear = (date: Date): string => {
    return format(date, "dd/MM/yyyy");
};

export const convertMonthToName = (month: string): string => {
    return MONTHS.find((item) => item.value === month)?.label ?? "";
};
