import React from "react";
import type { ReactElement } from "react";

import type {
    CompositeNavigationProp,
    RouteProp,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";

import AccountScreen, {
    screenOptions as accountScreenOptions,
} from "../screens/accounts/AccountScreen";
import InvoiceScreen, {
    screenOptions as invoiceScreenOptions,
} from "../screens/invoicing/InvoiceScreen";
import DefaultTermDatesScreen, {
    screenOptions as defaultTermDatesScreenOptions,
} from "../screens/schools/DefaultTermDatesScreen";
import SchoolScreen, {
    screenOptions as schoolScreenOptions,
} from "../screens/schools/SchoolScreen";
import EnrolmentScreen, {
    screenOptions as enrolmentScreenOptions,
} from "../screens/waitingList/EnrolmentScreen";
import WaitingListHubScreen, {
    screenOptions as waitingListHubScreenOptions,
} from "../screens/waitingList/WaitingListHubScreen";

import type { HubTabNavigatorProps } from "./HubNavigator";
import defaultNavOptions from "./utils/DefaultNavigationOptions";
import type {
    AccountParams,
    DefaultTermDatesParams,
    EnrolmentParams,
    InvoiceParams,
    SchoolParams,
} from "./utils/ScreenParams";

export type WaitingListNavigatorParamList = {
    Account: AccountParams;
    DefaultTermDates: DefaultTermDatesParams;
    Enrolment: EnrolmentParams;
    Invoice: InvoiceParams;
    School: SchoolParams;
    WaitingListHub?: {
        instrument?: string;
        lessonStageIndex?: string;
        orderBy?: string;
        parentEmail?: string;
        school?: string;
        schoolYear?: string;
        searchTerm?: string;
    };
};

type NavigatorProps<T extends keyof WaitingListNavigatorParamList> =
    StackNavigationProp<WaitingListNavigatorParamList, T>;

export type WaitingListStackNavigatorProps<
    T extends keyof WaitingListNavigatorParamList,
> = CompositeNavigationProp<
    NavigatorProps<T>,
    HubTabNavigatorProps<"WaitingListHubTab">
>;

export type WaitingListStackRouteProps<
    T extends keyof WaitingListNavigatorParamList,
> = RouteProp<WaitingListNavigatorParamList, T>;

const WaitingListStackNavigator =
    createStackNavigator<WaitingListNavigatorParamList>();

const WaitingListNavigator = (): ReactElement => {
    return (
        <WaitingListStackNavigator.Navigator screenOptions={defaultNavOptions}>
            <WaitingListStackNavigator.Screen
                component={WaitingListHubScreen}
                name="WaitingListHub"
                options={waitingListHubScreenOptions}
            />
            <WaitingListStackNavigator.Screen
                component={EnrolmentScreen}
                name="Enrolment"
                options={enrolmentScreenOptions}
            />
            <WaitingListStackNavigator.Screen
                component={SchoolScreen}
                name="School"
                options={schoolScreenOptions}
            />
            <WaitingListStackNavigator.Screen
                component={AccountScreen}
                name="Account"
                options={accountScreenOptions}
            />
            <WaitingListStackNavigator.Screen
                component={InvoiceScreen}
                name="Invoice"
                options={invoiceScreenOptions}
            />
            <WaitingListStackNavigator.Screen
                component={DefaultTermDatesScreen}
                name="DefaultTermDates"
                options={defaultTermDatesScreenOptions}
            />
        </WaitingListStackNavigator.Navigator>
    );
};

export default WaitingListNavigator;
