import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    Suspense,
    useMemo,
} from "react";
import type {
    ReactElement,
    ComponentProps,
    Dispatch,
    SetStateAction,
    MutableRefObject,
} from "react";

import { format } from "date-fns";
import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    VStack,
    HStack,
    Button,
    Text,
    Progress,
    Center,
    ChevronLeftIcon,
    ChevronRightIcon,
    useTheme,
    Pressable,
    Tooltip,
    useDisclose,
    PresenceTransition,
    Spinner,
    useToast,
    Checkbox,
    AddIcon,
} from "native-base";
import type { Mutable } from "pianofunclub-shared/types";
import { Platform, useWindowDimensions } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import {
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
    useRefetchableFragment,
    useMutation,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type { RegisterInfoFragment_query$key } from "pianofunclub-shared/relay/graphql/fragments/__generated__/RegisterInfoFragment_query.graphql";
import type { RegisterInfoRefreshQuery } from "pianofunclub-shared/relay/graphql/fragments/__generated__/RegisterInfoRefreshQuery.graphql";
import { register_fragment } from "pianofunclub-shared/relay/graphql/fragments/RegisterInfoFragment";
import type {
    AddReplacementLessonColumnToRegisterMutation,
    AddReplacementLessonColumnToRegisterMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/AddReplacementLessonColumnToRegisterMutation.graphql";
import type {
    LoadLessonBlocks_query_lessonBlocks$key,
    LoadLessonBlocks_query_lessonBlocks$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocks_query_lessonBlocks.graphql";
import type { LoadLessonBlocksPaginationQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksPaginationQuery.graphql";
import type {
    LoadLessonBlocksQuery,
    LoadLessonBlocksQuery$variables,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import type {
    RemoveLessonColumnMutation,
    RemoveLessonColumnMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RemoveLessonColumnMutation.graphql";
import type {
    RescheduleLessonsForWeekMutation,
    RescheduleLessonsForWeekMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RescheduleLessonsForWeekMutation.graphql";
import type {
    UpdateRegistersForWeekMutation,
    UpdateRegistersForWeekMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateRegistersForWeekMutation.graphql";
import { add_replacement_lesson_column_to_register } from "pianofunclub-shared/relay/graphql/registers/AddReplacementLessonColumnToRegister";
import {
    load_lesson_blocks,
    load_lesson_blocks_pagination,
} from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import { remove_lesson_column } from "pianofunclub-shared/relay/graphql/registers/RemoveLessonColumn";
import { reschedule_lessons_for_week } from "pianofunclub-shared/relay/graphql/registers/RescheduleLessonsForWeek";
import { update_registers_for_week } from "pianofunclub-shared/relay/graphql/registers/UpdateRegistersForWeek";

import { useAuth } from "../../providers/AuthProvider";
import type { SchoolEdges } from "../../providers/DataProvider";
import {
    getDefaultStartingYearAndStartingYearOptions,
    getFullName,
} from "../../utils/extractors";
import { useDebounceFunction } from "../../utils/hooks";
import ButtonDebounced from "../Buttons/ButtonDebounced";
import ManageRegisterLessonModal from "../Modals/ManageRegisterLessonModal";
import type { RegisterUpdate } from "../Modals/ManageRegisterLessonModal";
import Actionsheet from "../NativeBaseExtended/Actionsheet";
import ToastAlert from "../NativeBaseExtended/ToastAlert";

import LessonBlockTableRow from "pianofunclub-crm/components/ListItems/LessonBlockTableRow";
import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
    NavigationProps as AccountScreenNavigationProps,
} from "pianofunclub-crm/screens/accounts/AccountScreen";
import type {
    ReducerValues as TeacherRegistersHubScreenReducerValues,
    ReducerTypes as TeacherRegistersHubScreenReducerTypes,
    NavigationProps as TeacherRegistersHubScreenNavigationProps,
} from "pianofunclub-crm/screens/registers/TeacherRegistersHubScreen";
import { teacherRegistersTableCsvConverter } from "pianofunclub-crm/utils/tableCsvConverters";

import type {
    ReducerValues as RegistersScreenReducerValues,
    ReducerTypes as RegistersScreenReducerTypes,
    NavigationProps as RegistersScreenNavigationProps,
} from "pianofunclub-teaching-app/screens/RegistersScreen";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import type { TableData } from "pianofunclub-shared/components/Base/Row";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import DayPickerModal from "pianofunclub-shared/components/Modals/DayPickerModal";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AccountsIcon,
    AddCircleIcon,
    CalendarIcon,
    CopyIcon,
    EditIcon,
    OptionsIcon,
    RegisterIcon,
    RestartIcon,
    SwapIconCircle,
    TimetableIcon,
    TrashIcon,
    UserClockIcon,
    UserSlashIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import { BLOCKS, TEACHING_DAYS } from "pianofunclub-shared/utils/constants";
import {
    getScaledWindowDimension,
    teachingDaysToString,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

export type LessonBlockData = NonNullable<
    LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
>["edges"][0];

export type RegisterProgress = {
    marked: number | undefined;
    total: number | undefined;
};

export type RegisterDates = (
    | {
          actualDate: Date | undefined;
          isReplacementLesson: boolean;
          replacementColumnIndex?: number | undefined;
          scheduledDate: Date | undefined;
      }
    | undefined
)[];

const LOAD_X_LESSON_BLOCKS = Platform.OS === "web" ? 15 : 5;

interface Props extends VStackProps {
    allSchools: SchoolEdges;
    dispatchState: Dispatch<
        Action<
            AccountScreenReducerValues &
                RegistersScreenReducerValues &
                TeacherRegistersHubScreenReducerValues,
            | AccountScreenReducerTypes
            | RegistersScreenReducerTypes
            | TeacherRegistersHubScreenReducerTypes
        >
    >;
    lessonBlocksConnectionId: string | undefined;
    loadLessonBlocksQuery: (
        variables: LoadLessonBlocksQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions,
    ) => void;
    loadLessonBlocksQueryReference:
        | PreloadedQuery<LoadLessonBlocksQuery, Record<string, unknown>>
        | null
        | undefined;
    navigation: AccountScreenNavigationProps &
        RegistersScreenNavigationProps &
        TeacherRegistersHubScreenNavigationProps;
    profileFirstName: string | undefined;
    profileId: string;
    profileLastName: string | undefined;
    registerProgressCounter: MutableRefObject<RegisterProgress>;
    registerUpdates: MutableRefObject<RegisterUpdate[]>;
    removeLessonBlocks: (
        lessonBlockIds: string[],
        connectionId?: string,
        onComplete?: () => void,
    ) => void;
    removeLessonBlocksInFlight: boolean;
    showUpdateBlockDetailsModalHandler: (variables: {
        keepFree?: boolean;
        lessonBlockCursor?: string;
        lessonBlockIds: string[];
        onUpdate?: () => void;
        school?: string;
        selectedProfileFullName?: string;
        selectedProfileId?: string;
        type: string;
    }) => void;
    sideBarWidth: number;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<
        | AccountScreenReducerValues
        | RegistersScreenReducerValues
        | TeacherRegistersHubScreenReducerValues
    >;
    teacherSchools?:
        | readonly ({
              readonly id: string;
              readonly name: string;
          } | null)[]
        | null;
    updateLessonBlockDetails: (
        variables: {
            archiveBlock?: boolean;
            instrument?: string;
            keepFree?: boolean;
            lessonBlockIds: string[];
            pupilId?: string | null;
            staffNoteForTeacher?: string;
            stageId?: string;
            teacherId?: string | null;
            teacherNoteForStaff?: string;
        },
        lessonBlockCursor?: string,
        onComplete?: () => void,
    ) => void;
    updateRegisterState: (
        variables: {
            lessonId: string;
            registerNote?: string;
            status: string;
        },
        progressChange?: number,
    ) => void;
    updateScheduledLessonBlockTime?: (
        lessonBlockIds: string[],
        dayIndex: number,
        hours?: number,
        minutes?: number,
        onComplete?: () => void,
    ) => void;
    updateScheduledLessonBlockTimeInFlight?: boolean;
    userIsTeacher?: boolean;
}

interface ContentProps extends Props {
    dataProvider: DataProvider;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lessonBlockRefs: MutableRefObject<Map<any, any>>;
    loadLessonBlocksQueryReference: PreloadedQuery<
        LoadLessonBlocksQuery,
        Record<string, unknown>
    >;
    refreshHandler: () => void;
    selectedBlocks: string[];
    setCopyDataToClipboard: Dispatch<
        SetStateAction<(() => void | undefined) | undefined>
    >;
    setDataProvider: Dispatch<SetStateAction<DataProvider>>;
    setSelectedBlocks: Dispatch<SetStateAction<string[]>>;
}

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;

export type LessonDataType =
    | {
          actualDate?: Date;
          isReplacementLesson: boolean;
          replacementColumnIndex?: number;
          scheduledDate?: Date | undefined;
      }
    | undefined;

const TeacherRegistersContent = (props: ContentProps): ReactElement => {
    const {
        dataProvider,
        dispatchState,
        lessonBlockRefs,
        loadLessonBlocksQueryReference,
        navigation,
        profileId,
        refreshHandler,
        registerProgressCounter,
        registerUpdates,
        selectedBlocks,
        setCopyDataToClipboard,
        setDataProvider,
        setSelectedBlocks,
        showUpdateBlockDetailsModalHandler,
        sideBarWidth,
        state,
        updateRegisterState,
        updateScheduledLessonBlockTime,
        userIsTeacher,
    } = props;

    const { user } = useAuth();

    const data = usePreloadedQuery(
        load_lesson_blocks,
        loadLessonBlocksQueryReference,
    );

    const [registerInfo, refetchRegisterInfo] = useRefetchableFragment<
        RegisterInfoRefreshQuery,
        RegisterInfoFragment_query$key
    >(register_fragment, data);

    useEffect(() => {
        dispatchState({
            input: "teachingDays",
            value: registerInfo.profile?.teachingDays ?? undefined,
        });
        dispatchState({
            input: "dayIndex",
            value:
                registerInfo.profile?.teachingDays &&
                registerInfo.profile.teachingDays.length > 0 &&
                !registerInfo.profile.teachingDays.includes(
                    state.values.dayIndex,
                )
                    ? (registerInfo.profile.teachingDays[0] ??
                      state.values.dayIndex)
                    : state.values.dayIndex,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchState, registerInfo.profile?.teachingDays]);

    const [lessonBlockIds, setLessonBlockIds] = useState<string[]>([]);

    const {
        data: lessonBlocksData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadLessonBlocksPaginationQuery,
        LoadLessonBlocks_query_lessonBlocks$key
    >(load_lesson_blocks_pagination, data);

    const datesToRender = useMemo(() => {
        const firstLessonBlock = lessonBlocksData?.lessonBlocks?.edges[0]?.node;

        return (
            firstLessonBlock?.lessons?.edges?.map((item) => {
                if (!item?.node) {
                    return undefined;
                }

                const isReplacementLesson =
                    typeof item.node.replacementLessonColumnIndex === "number";

                let actualDate: Date | undefined = undefined;
                let scheduledDate: Date | undefined = undefined;

                // if the week has been rearranged, use the rearranged date
                if (
                    item.node.rearrangedTimestamp &&
                    item?.node.weekWasRearranged
                ) {
                    actualDate = new Date(item.node.rearrangedTimestamp);
                    scheduledDate = item.node.scheduledTimestamp
                        ? new Date(item.node.scheduledTimestamp)
                        : undefined;
                } else if (item.node.scheduledTimestamp) {
                    actualDate = new Date(item.node.scheduledTimestamp);
                    scheduledDate = actualDate;
                }

                if (isReplacementLesson) {
                    return {
                        actualDate,
                        scheduledDate,
                        isReplacementLesson,
                        replacementColumnIndex:
                            item.node.replacementLessonColumnIndex,
                    };
                } else if (actualDate) {
                    // non-replacement lessons with a defined date
                    return {
                        actualDate,
                        scheduledDate,
                        isReplacementLesson: false,
                    };
                }

                return undefined;
            }) ?? ([] as RegisterDates)
        );
    }, [lessonBlocksData?.lessonBlocks?.edges]);

    const currentStartingYear = useMemo(() => {
        return (
            user?.profile?.profileGroup?.currentStartingYear ??
            getDefaultStartingYearAndStartingYearOptions()[0]
        );
    }, [user?.profile?.profileGroup?.currentStartingYear]);

    const flexArray = useMemo(() => {
        const flexArray =
            Platform.OS === "web"
                ? [0.8, 1, 0.5, 0.4, 0.6, 1, 0.3]
                : [0.8, 1, 0.6, 1, 0.5];
        flexArray.push(...Array(datesToRender.length).fill(0.5));
        if (Platform.OS === "web" && !userIsTeacher) {
            flexArray.unshift(0.3);
        }
        return flexArray;
    }, [datesToRender.length, userIsTeacher]);

    useEffect(() => {
        dispatchState({
            input: "lessonBlocksConnectionId",
            value: lessonBlocksData.lessonBlocks?.__id,
        });
        if (
            lessonBlocksData.lessonBlocks?.edges &&
            lessonBlocksData.lessonBlocks.edges.length > 0
        ) {
            setDataProvider(
                dataProvider.cloneWithRows(
                    // hide any lesson blocks that are not taught by the teacher
                    // and are not the correct lesson day
                    // this happens when changing the teacher/day of lesson block(s)
                    lessonBlocksData.lessonBlocks.edges.filter(
                        (item) =>
                            item?.node?.teacher?.id === profileId &&
                            item?.node?.lessonDay === state.values.dayIndex,
                    ) as Mutable<typeof lessonBlocksData.lessonBlocks.edges>,
                ),
            );
            setLessonBlockIds(
                lessonBlocksData.lessonBlocks.edges.map(
                    (item) => item?.node?.id ?? "",
                ),
            );
            // if there is no data, create a fresh DataProvider
        } else {
            setDataProvider(
                new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            );
            setLessonBlockIds([]);
        }

        setCopyDataToClipboard(() => () => {
            Clipboard.setStringAsync(
                teacherRegistersTableCsvConverter(
                    lessonBlocksData.lessonBlocks?.edges ?? [],
                    datesToRender ?? [],
                ),
            );
            toast.show({
                render: ({ id }: { id: string }) => (
                    <ToastAlert
                        id={id}
                        status="success"
                        title={"Copied Data to Clipboard"}
                        toast={toast}
                    />
                ),
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        lessonBlocksData.lessonBlocks?.__id,
        lessonBlocksData.lessonBlocks?.edges,
        loadLessonBlocksQueryReference,
    ]);

    useEffect(() => {
        if (registerInfo.profile?.totalLessonsMarked != null) {
            registerProgressCounter.current.marked =
                registerInfo.profile.totalLessonsMarked;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerInfo.profile?.totalLessonsMarked]);

    useEffect(() => {
        if (registerInfo.profile?.totalLessons != null) {
            registerProgressCounter.current.total =
                registerInfo.profile.totalLessons;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerInfo.profile?.totalLessons]);

    const [layoutProvider, setLayoutProvider] = useState(
        new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = getScaledWindowDimension(
                            windowWidth,
                            scale,
                        );
                        dim.height = 40;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        ),
    );

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_LESSON_BLOCKS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    const { colors } = useTheme();
    // @ts-expect-error ref for RLV not typed properly
    const recyclerListViewRef = useRef<RecyclerListView>();
    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    const refetchLessonBlocks = useCallback(
        (
            variables: {
                block: number;
                dayIndex: number;
                school?: string;
                searchTerm?: string;
                startingYear: number;
            },
            options?: { forceRefetch?: boolean; skipLessonBlocks?: boolean },
        ): Subscription | undefined => {
            if (!options?.skipLessonBlocks) {
                dispatchState({ input: "isRefetching", value: true });
            }

            const fullVariables = {
                ...variables,
                first: LOAD_X_LESSON_BLOCKS,
                skipLessonBlocks: options?.skipLessonBlocks ?? false,
                skipRegisterProgress: false,
                skipLessonStages: true,
                profileId: profileId,
            };

            const subscription = fetchQuery<LoadLessonBlocksQuery>(
                environment,
                load_lesson_blocks,
                fullVariables,
                {
                    fetchPolicy: options?.forceRefetch
                        ? "network-only"
                        : "store-or-network",
                },
            ).subscribe({
                complete: () => {
                    if (!options?.skipLessonBlocks) {
                        setSelectedBlocks([]);
                        lessonBlockRefs.current.forEach((ref) => {
                            ref.setIsChecked(false);
                        });
                        lessonBlockRefs.current = new Map();
                    }
                    dispatchState({ input: "isRefetching", value: false });
                    refetchRegisterInfo(fullVariables, {
                        fetchPolicy: "store-only",
                    });
                    refresh(fullVariables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatchState, environment, profileId, refetchRegisterInfo, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        dispatchState({
            input: "subscription",
            value: refetchLessonBlocks({
                searchTerm: state.values.searchTerm.trim(),
                startingYear: state.values.startingYear,
                block: state.values.block,
                school: state.values.school,
                dayIndex: state.values.dayIndex,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.startingYear,
        state.values.block,
        state.values.school,
        state.values.dayIndex,
    ]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 4) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            dispatchState({
                input: "subscription",
                value: refetchLessonBlocks({
                    searchTerm: state.values.searchTerm.trim(),
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    school: state.values.school,
                    dayIndex: state.values.dayIndex,
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        refreshHandler();
        // @ts-expect-error state is merged on component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values?.currentPageIndex]);

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }
        // this allows the RLV to resize when the window resizes
        setLayoutProvider(
            new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width =
                                getScaledWindowDimension(windowWidth, scale) -
                                sideBarWidth;
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        );
    }, [scale, windowWidth, sideBarWidth]);

    const toast = useToast();
    const {
        isOpen: columnOptionsIsOpen,
        onClose: columnOptionsOnClose,
        onOpen: columnOptionsOnOpen,
    } = useDisclose();
    const [selectedColumnState, setSelectedColumnState] =
        useState<LessonDataType>();
    const [datePickerState, setDatePickerState] = useState<{
        // this is the initial picker date - will match the date of the lesson
        // (rearranged or not)
        date: Date | undefined;
        isOpen: boolean;
        replacementLessonColumnIndex: number | undefined;
        // this is the orginal scheduled date of the lessons - used to find the lessons
        // when running mutations
        scheduledDate: Date | undefined;
    }>({
        isOpen: false,
        date: undefined,
        scheduledDate: undefined,
        replacementLessonColumnIndex: undefined,
    });
    const [
        addReplacementColumnDatePickerState,
        setAddReplacementColumnDatePickerState,
    ] = useState<{
        date?: Date;
        isOpen: boolean;
    }>({
        isOpen: false,
        date: undefined,
    });
    const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);

    const [commitRescheduleLessonsForWeek, rescheduleLessonsForWeekInFlight] =
        useMutation<RescheduleLessonsForWeekMutation>(
            reschedule_lessons_for_week,
        );

    const rescheduleLessonsForWeek = useCallback(
        (variables: {
            rearrangedDate: Date;
            replacementLessonColumnIndex?: number;
            scheduledDate: Date;
            schoolName?: string;
        }) => {
            const rescheduleLessonsForWeekConfig = {
                variables: {
                    input: {
                        teacherId: profileId,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        dayIndex: state.values.dayIndex,
                        scheduledDate: format(
                            variables.scheduledDate,
                            "yyyy-MM-dd",
                        ),
                        schoolName: variables.schoolName,
                        rearrangedDate: format(
                            variables.rearrangedDate,
                            "yyyy-MM-dd",
                        ),
                        replacementLessonColumnIndex:
                            variables.replacementLessonColumnIndex,
                    },
                },
                onCompleted: (
                    response: RescheduleLessonsForWeekMutation$data,
                ) => {
                    if (response?.rescheduleLessonsForWeek?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Rescheduled lessons for week"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't reschedule lessons"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRescheduleLessonsForWeek(rescheduleLessonsForWeekConfig);
        },
        [
            commitRescheduleLessonsForWeek,
            profileId,
            state.values.block,
            state.values.dayIndex,
            state.values.startingYear,
            toast,
        ],
    );

    const [commitUpdateRegistersForWeek, updateRegistersForWeekInFlight] =
        useMutation<UpdateRegistersForWeekMutation>(update_registers_for_week);

    const updateRegistersForWeek = useCallback(
        (variables: {
            replacementLessonIndex?: number;
            scheduledDate?: Date;
            status: string;
        }) => {
            const updateRegistersForWeekConfig = {
                variables: {
                    input: {
                        teacherId: profileId,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        dayIndex: state.values.dayIndex,
                        scheduledDate: variables.scheduledDate
                            ? format(variables.scheduledDate, "yyyy-MM-dd")
                            : undefined,
                        replacementLessonIndex:
                            variables.replacementLessonIndex,
                        schoolName: state.values.school,
                        status: variables.status,
                    },
                },
                onCompleted: (
                    response: UpdateRegistersForWeekMutation$data,
                ) => {
                    refetchLessonBlocks(
                        {
                            searchTerm: state.values.searchTerm.trim(),
                            startingYear: state.values.startingYear,
                            block: state.values.block,
                            school: state.values.school,
                            dayIndex: state.values.dayIndex,
                        },
                        { forceRefetch: true, skipLessonBlocks: true },
                    );
                    if (response?.updateRegistersForWeek?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Updated registers for week"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update registers"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    setSelectedColumnState(undefined);
                },
            };

            commitUpdateRegistersForWeek(updateRegistersForWeekConfig);
        },
        [
            commitUpdateRegistersForWeek,
            profileId,
            refetchLessonBlocks,
            state.values.block,
            state.values.dayIndex,
            state.values.school,
            state.values.searchTerm,
            state.values.startingYear,
            toast,
        ],
    );

    const [
        commitAddReplacementLessonColumnToRegister,
        addReplacementLessonColumnToRegisterInFlight,
    ] = useMutation<AddReplacementLessonColumnToRegisterMutation>(
        add_replacement_lesson_column_to_register,
    );

    const addReplacementLessonColumnToRegister = useCallback(
        (variables: { scheduledDate: Date }) => {
            const addReplacementLessonColumnToRegisterConfig = {
                variables: {
                    input: {
                        teacherId: profileId,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        dayIndex: state.values.dayIndex,
                        scheduledDate: format(
                            variables.scheduledDate,
                            "yyyy-MM-dd",
                        ),
                    },
                },
                onCompleted: (
                    response: AddReplacementLessonColumnToRegisterMutation$data,
                ) => {
                    if (
                        response?.addReplacementLessonColumnToRegister?.success
                    ) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Added replacement lesson column"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        "Couldn't add replacement lesson column"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitAddReplacementLessonColumnToRegister(
                addReplacementLessonColumnToRegisterConfig,
            );
        },
        [
            commitAddReplacementLessonColumnToRegister,
            profileId,
            state.values.block,
            state.values.dayIndex,
            state.values.startingYear,
            toast,
        ],
    );

    const [commitRemoveLessonColumn, removeLessonColumnInFlight] =
        useMutation<RemoveLessonColumnMutation>(remove_lesson_column);

    const removeLessonColumn = useCallback(
        (variables: {
            replacementLessonColumnIndex?: number;
            scheduledDate?: string;
        }) => {
            const removeLessonColumnConfig = {
                variables: {
                    input: {
                        teacherId: profileId,
                        startingYear: Number(state.values.startingYear),
                        block: Number(state.values.block),
                        dayIndex: state.values.dayIndex,
                        scheduledDate: variables.scheduledDate,
                        replacementLessonColumnIndex:
                            variables.replacementLessonColumnIndex,
                    },
                },
                onCompleted: (response: RemoveLessonColumnMutation$data) => {
                    columnOptionsOnClose();
                    setTimeout(() => {
                        setSelectedColumnState(undefined);
                    }, 500);
                    if (response?.removeLessonColumn?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Removed lesson column"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't remove lesson column"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitRemoveLessonColumn(removeLessonColumnConfig);
        },
        [
            columnOptionsOnClose,
            commitRemoveLessonColumn,
            profileId,
            state.values.block,
            state.values.dayIndex,
            state.values.startingYear,
            toast,
        ],
    );

    const updateRegistersForWeekHandler = useCallback(
        (variables: { status: string }) => {
            setRegisterModalIsOpen(false);
            if (!selectedColumnState) {
                return;
            }

            updateRegistersForWeek({
                ...(selectedColumnState.isReplacementLesson
                    ? {
                          replacementLessonIndex:
                              selectedColumnState.replacementColumnIndex,
                      }
                    : {
                          scheduledDate: selectedColumnState.scheduledDate,
                      }),
                ...variables,
            });
        },
        [selectedColumnState, updateRegistersForWeek],
    );

    const onPressProfile = useCallback(
        (profileId?: string | null) => {
            if (profileId) {
                navigation.push("Account", {
                    profileId: profileId,
                    accountType: "PUPIL",
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                });
            }
        },
        [navigation, state.values.block, state.values.startingYear],
    );

    const onPressSchool = useCallback(
        (schoolId?: string | null) => {
            if (schoolId) {
                navigation.push("School", {
                    schoolId: schoolId,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                });
            }
        },
        [navigation, state.values.block, state.values.startingYear],
    );

    const checkboxChangeHandler = useCallback(
        (blockId: string, isSelected: boolean) => {
            if (isSelected) {
                setSelectedBlocks((blocks) => blocks.concat(blockId));
            } else {
                setSelectedBlocks((blocks) =>
                    blocks.filter((id) => id !== blockId),
                );
            }
        },
        [setSelectedBlocks],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            lessonBlockRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            setSelectedBlocks(isSelected ? lessonBlockIds : []);
        },
        [lessonBlockIds, lessonBlockRefs, setSelectedBlocks],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: LessonBlockData,
            index: number,
        ): ReactElement | null => {
            if (!data?.node) {
                return null;
            }

            return (
                <Box
                    borderColor={TABLE_BORDER_COLOR}
                    borderLeftWidth={TABLE_BORDER_WIDTH}>
                    <LessonBlockTableRow
                        ref={(ref) => {
                            if (
                                ref &&
                                data.node?.id &&
                                !lessonBlockRefs.current.get(data.node.id)
                            ) {
                                lessonBlockRefs.current.set(data.node.id, ref);
                            }
                        }}
                        cellProps={{
                            hideTopBorder: index === 0,
                            pressableTextProps: {
                                fontFamily: "Poppins-Regular",
                            },
                            textProps: {
                                fontSize:
                                    Platform.OS === "web" && windowWidth > 1000
                                        ? "md"
                                        : "sm",
                                textAlign: "center",
                                fontFamily: "Poppins-Light",
                                isTruncated: true,
                            },
                            placeholderTextProps: { color: "muted.400" },
                            px: 2,
                        }}
                        checkboxChangeHandler={checkboxChangeHandler}
                        currentStartingYear={currentStartingYear}
                        cursor={data.cursor}
                        data={data.node}
                        datesToRender={datesToRender}
                        flexArray={flexArray}
                        isRegisterTable
                        onPressProfile={onPressProfile}
                        onPressSchool={onPressSchool}
                        registerUpdates={registerUpdates}
                        rowHeight={10}
                        selectedStartingYear={state.values.startingYear}
                        showUpdateBlockDetailsModalHandler={
                            showUpdateBlockDetailsModalHandler
                        }
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                        updateRegisterState={updateRegisterState}
                        updateScheduledLessonBlockTime={
                            updateScheduledLessonBlockTime
                        }
                        userIsTeacher={userIsTeacher}
                    />
                </Box>
            );
        },
        [
            checkboxChangeHandler,
            currentStartingYear,
            datesToRender,
            flexArray,
            lessonBlockRefs,
            onPressProfile,
            onPressSchool,
            registerUpdates,
            showUpdateBlockDetailsModalHandler,
            state.values.startingYear,
            updateRegisterState,
            updateScheduledLessonBlockTime,
            userIsTeacher,
            windowWidth,
        ],
    );

    const renderFooter = useMemo(() => {
        const renderFooter = () =>
            isLoadingNext ? (
                <CustomFlatListSpinner
                    borderColor={TABLE_BORDER_COLOR}
                    borderTopWidth={1}
                    position="relative"
                    pt="8"
                    top="0"
                />
            ) : null;
        return renderFooter;
    }, [isLoadingNext]);

    const renderListEmptyBanner = useMemo(() => {
        return (
            <ListEmptyBanner
                explainer={
                    state.values.searchTerm || state.values.school
                        ? "No registers found..."
                        : (state.values.teachingDays?.length ?? 0) > 0
                          ? "No lessons on this day"
                          : "No lessons in this block"
                }
                flexGrow={1}>
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, [
        state.values.school,
        state.values.searchTerm,
        state.values.teachingDays?.length,
    ]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="60" />
        ) : null;
    }, [state.values.isRefetching]);

    const renderTableHeader = useMemo(() => {
        const tableHeaders: TableData =
            Platform.OS === "web"
                ? [
                      {
                          data: "Time",
                      },
                      {
                          data: "School",
                      },
                      {
                          data: "Type",
                      },
                      {
                          data: "Stage",
                      },
                      {
                          data: "Instrument",
                      },
                  ]
                : [
                      {
                          data: "Time",
                      },
                      {
                          data: "School",
                      },
                      {
                          data: "Instrument",
                      },
                  ];
        tableHeaders.push(
            {
                data: "Pupil",
            },
            {
                data: "Year",
            },
            ...datesToRender.map((item) => {
                const isCurrentlySelectedColumn =
                    (item?.isReplacementLesson &&
                        item?.replacementColumnIndex ===
                            selectedColumnState?.replacementColumnIndex) ||
                    (!item?.isReplacementLesson &&
                        item?.scheduledDate ===
                            selectedColumnState?.scheduledDate);

                return {
                    data:
                        isCurrentlySelectedColumn &&
                        (rescheduleLessonsForWeekInFlight ||
                            updateRegistersForWeekInFlight) ? (
                            <Spinner />
                        ) : item?.actualDate ? (
                            item?.isReplacementLesson === true ? (
                                <>
                                    <HStack space="1.5">
                                        <SwapIconCircle
                                            color="surface.900"
                                            size="5"
                                        />
                                        <Text
                                            fontSize={
                                                Platform.OS === "web"
                                                    ? "md"
                                                    : "sm"
                                            }>
                                            {format(item.actualDate, "dd/MM")}
                                        </Text>
                                    </HStack>
                                </>
                            ) : (
                                format(item.actualDate, "dd/MM")
                            )
                        ) : (
                            <>
                                <HStack space="1.5">
                                    <SwapIconCircle
                                        color="surface.900"
                                        size="5"
                                    />
                                    <Text
                                        fontSize={
                                            Platform.OS === "web" ? "md" : "sm"
                                        }>
                                        ...
                                    </Text>
                                </HStack>
                            </>
                        ),
                    onPress: () => {
                        setSelectedColumnState(item);
                        columnOptionsOnOpen();
                    },
                };
            }),
        );
        if (Platform.OS === "web" && !userIsTeacher) {
            tableHeaders.unshift({
                data: (
                    <Checkbox
                        isChecked={
                            selectedBlocks.length != 0 &&
                            lessonBlockIds.length <= selectedBlocks.length &&
                            lessonBlockIds.every((id) =>
                                selectedBlocks.includes(id),
                            )
                        }
                        onChange={selectAllCheckboxChangeHandler}
                        size="md"
                        value="SELECT_ALL"
                    />
                ),
                onPress: () => {
                    return;
                },
            });
        }
        return (
            <Box bg="surface.100">
                <Box
                    bg="primary.50"
                    borderColor={TABLE_BORDER_COLOR}
                    borderTopRadius={TABLE_BORDER_RADIUS}
                    borderWidth={TABLE_BORDER_WIDTH}>
                    <Row
                        cellProps={{
                            px: 2,
                            hideTopBorder: true,
                            hideOuterSideBorder: true,
                            textProps: {
                                fontSize: Platform.OS === "web" ? "md" : "sm",
                                textAlign: "center",
                            },
                        }}
                        data={tableHeaders}
                        flexArray={flexArray}
                        rowHeight={10}
                        rowIndex={0}
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                    />
                </Box>
                {!userIsTeacher ? (
                    <ButtonDebounced
                        _hover={{ opacity: 1, bg: "secondary.600" }}
                        _pressed={{ opacity: 1, bg: "secondary.700" }}
                        borderRadius="full"
                        colorScheme="secondary"
                        leftIcon={
                            <Center size="5">
                                {addReplacementLessonColumnToRegisterInFlight ? (
                                    <Spinner color="white" size="sm" />
                                ) : (
                                    <AddIcon color="white" size="4" />
                                )}
                            </Center>
                        }
                        onPress={() => {
                            setAddReplacementColumnDatePickerState({
                                isOpen: true,
                                date: new Date(),
                            });
                        }}
                        position="absolute"
                        right="-12"
                        shadow="2"
                        size="7"
                        top="-12"
                    />
                ) : null}
            </Box>
        );
    }, [
        datesToRender,
        userIsTeacher,
        flexArray,
        addReplacementLessonColumnToRegisterInFlight,
        selectedColumnState?.replacementColumnIndex,
        selectedColumnState?.scheduledDate,
        rescheduleLessonsForWeekInFlight,
        updateRegistersForWeekInFlight,
        columnOptionsOnOpen,
        selectedBlocks,
        lessonBlockIds,
        selectAllCheckboxChangeHandler,
    ]);

    const renderColumnActionsheet = useMemo(() => {
        const getTitle = () => {
            const { actualDate, isReplacementLesson, replacementColumnIndex } =
                selectedColumnState ?? {};
            const formattedDate = actualDate
                ? format(actualDate, "do MMM yyyy")
                : "";

            let message = "";
            if (isReplacementLesson) {
                if (actualDate) {
                    message = `For replacement lessons on ${formattedDate}`;
                } else if (replacementColumnIndex !== undefined) {
                    // Assuming replacementColumnIndex can be 0, which is falsy
                    message = `For replacement week ${
                        replacementColumnIndex + 1
                    }`;
                }
            } else if (isReplacementLesson === false && actualDate) {
                message = `For lessons on ${formattedDate}`;
            }

            return message;
        };

        const title = getTitle();

        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={columnOptionsIsOpen}
                justifyContent="center"
                onClose={() => {
                    columnOptionsOnClose();
                    setTimeout(() => {
                        setSelectedColumnState(undefined);
                    }, 500);
                }}
                size="lg">
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%">
                    <Text
                        alignSelf="center"
                        fontSize="2xl"
                        fontWeight="bold"
                        py="4">
                        Options
                    </Text>
                    {title ? (
                        <Text
                            alignSelf="center"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            mx="8"
                            pb="2"
                            textAlign="center">
                            {title}
                        </Text>
                    ) : null}
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={<RegisterIcon color="primary.500" size="6" />}
                        onPress={() => {
                            columnOptionsOnClose();
                            setRegisterModalIsOpen(true);
                        }}>
                        Update register for week
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <TimetableIcon color="primary.600" size="6" />
                        }
                        onPress={() => {
                            setDatePickerState({
                                isOpen: true,
                                date:
                                    selectedColumnState?.actualDate ??
                                    new Date(),
                                scheduledDate:
                                    selectedColumnState?.scheduledDate,
                                replacementLessonColumnIndex:
                                    selectedColumnState?.replacementColumnIndex,
                            });
                            columnOptionsOnClose();
                            setTimeout(
                                () => setSelectedColumnState(undefined),
                                500,
                            );
                        }}>
                        {!selectedColumnState?.isReplacementLesson
                            ? "Reschedule lessons for week"
                            : selectedColumnState?.actualDate
                              ? "Reschedule replacement lessons for week"
                              : "Assign date to replacement lessons for week"}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="6">
                                {removeLessonColumnInFlight ? (
                                    <Spinner color="surface.800" size="sm" />
                                ) : (
                                    <TrashIcon color="surface.800" size="6" />
                                )}
                            </Center>
                        }
                        onPress={() =>
                            removeLessonColumn({
                                scheduledDate: selectedColumnState?.actualDate
                                    ? format(
                                          selectedColumnState?.actualDate,
                                          "yyyy-MM-dd",
                                      )
                                    : undefined,
                                replacementLessonColumnIndex:
                                    selectedColumnState?.replacementColumnIndex,
                            })
                        }>
                        {`Remove ${
                            selectedColumnState?.isReplacementLesson
                                ? "replacement "
                                : ""
                        }lesson column`}
                    </Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        columnOptionsIsOpen,
        columnOptionsOnClose,
        removeLessonColumn,
        removeLessonColumnInFlight,
        selectedColumnState,
    ]);

    const renderModals = useMemo(() => {
        return (
            <>
                <ManageRegisterLessonModal
                    hideModal={() => setRegisterModalIsOpen(false)}
                    isUpdatingForWeek
                    lessonInfo={{
                        status: "PRESENT",
                        scheduledTimestamp: selectedColumnState?.actualDate
                            ? format(
                                  selectedColumnState?.actualDate,
                                  "do MMM yyyy",
                              )
                            : undefined,
                    }}
                    showModal={registerModalIsOpen}
                    updateRegistersForWeek={updateRegistersForWeekHandler}
                    userIsTeacher={userIsTeacher}
                />
                <DatePickerModal
                    animationType="fade"
                    date={datePickerState.date ?? new Date()}
                    label={"Rearrange Lessons"}
                    locale="en"
                    mode="single"
                    onConfirm={(params) => {
                        if (
                            params.date &&
                            datePickerState.scheduledDate !== undefined
                        ) {
                            rescheduleLessonsForWeek({
                                schoolName: state.values.school,
                                scheduledDate: datePickerState.scheduledDate,
                                rearrangedDate: params.date,
                                replacementLessonColumnIndex:
                                    datePickerState.replacementLessonColumnIndex,
                            });
                        }
                        setDatePickerState({
                            isOpen: false,
                            date: undefined,
                            scheduledDate: undefined,
                            replacementLessonColumnIndex: undefined,
                        });
                    }}
                    onDismiss={() =>
                        setDatePickerState({
                            isOpen: false,
                            date: undefined,
                            scheduledDate: undefined,
                            replacementLessonColumnIndex: undefined,
                        })
                    }
                    saveLabel="Rearrange"
                    uppercase={false}
                    visible={datePickerState.isOpen}
                />
                <DatePickerModal
                    animationType="fade"
                    date={datePickerState.date ?? new Date()}
                    label={"Schedule Replacement Lessons"}
                    locale="en"
                    mode="single"
                    onConfirm={(params) => {
                        if (
                            params.date &&
                            typeof addReplacementColumnDatePickerState.date !==
                                "undefined"
                        ) {
                            addReplacementLessonColumnToRegister({
                                scheduledDate: params.date,
                            });
                        }
                        setAddReplacementColumnDatePickerState({
                            isOpen: false,
                            date: undefined,
                        });
                    }}
                    onDismiss={() =>
                        setAddReplacementColumnDatePickerState({
                            isOpen: false,
                            date: undefined,
                        })
                    }
                    saveLabel="Add"
                    uppercase={false}
                    visible={addReplacementColumnDatePickerState.isOpen}
                />
            </>
        );
    }, [
        registerModalIsOpen,
        userIsTeacher,
        updateRegistersForWeekHandler,
        selectedColumnState?.actualDate,
        datePickerState.isOpen,
        datePickerState.date,
        datePickerState.scheduledDate,
        datePickerState.replacementLessonColumnIndex,
        addReplacementColumnDatePickerState.isOpen,
        addReplacementColumnDatePickerState.date,
        rescheduleLessonsForWeek,
        state.values.school,
        addReplacementLessonColumnToRegister,
    ]);

    useEffect(() => {
        if (dataProvider.getSize() > 0) {
            recyclerListViewRef?.current?.scrollToIndex(0, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.dayIndex]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datesToRender]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Registers...</LoadingBlobs>;
    }

    return (
        <>
            <VStack flex={1} flexGrow={1}>
                {renderTableHeader}
                <Box flex={1} minHeight={1} minWidth={1}>
                    {dataProvider.getSize() > 0 ? (
                        <RecyclerListView
                            ref={recyclerListViewRef}
                            canChangeSize
                            dataProvider={dataProvider}
                            layoutProvider={layoutProvider}
                            onEndReached={onEndReached}
                            renderFooter={renderFooter}
                            rowRenderer={rowRenderer}
                            scrollViewProps={{
                                contentContainerStyle: {
                                    borderColor:
                                        // @ts-expect-error can't index with variable
                                        colors?.[
                                            TABLE_BORDER_COLOR.split(".")[0]
                                        ][TABLE_BORDER_COLOR.split(".")[1]],
                                    borderBottomWidth:
                                        (lessonBlocksData.lessonBlocks?.edges
                                            .length ??
                                            0 > 0) &&
                                        !isLoadingNext
                                            ? TABLE_BORDER_WIDTH
                                            : 0,
                                    marginBottom: 40,
                                    overflow: "hidden",
                                },
                                showsVerticalScrollIndicator: false,
                            }}
                            style={{
                                flex: 1,
                                minHeight: 1,
                            }}
                            suppressBoundedSizeException
                            useWindowScroll
                        />
                    ) : (
                        renderListEmptyBanner
                    )}
                </Box>
                {renderRefetchIndicator}
            </VStack>
            {renderColumnActionsheet}
            {renderModals}
        </>
    );
};

const TeacherRegisters = (props: Props): ReactElement => {
    const {
        allSchools,
        dispatchState,
        lessonBlocksConnectionId,
        loadLessonBlocksQuery,
        loadLessonBlocksQueryReference,
        navigation,
        profileFirstName,
        profileId,
        profileLastName,
        registerProgressCounter,
        registerUpdates,
        removeLessonBlocks,
        removeLessonBlocksInFlight,
        showUpdateBlockDetailsModalHandler,
        startingYears,
        state,
        teacherSchools,
        updateLessonBlockDetails,
        updateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
        userIsTeacher,
    } = props;

    const [dataProvider, setDataProvider] = useState(
        new DataProvider((r1, r2) => {
            return r1 !== r2;
        }),
    );
    const [copyDataToClipboard, setCopyDataToClipboard] =
        useState<() => void | undefined>();

    const { width: windowWidth } = useWindowDimensions();

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue?.trim(),
            });
            navigation.setParams({
                searchTerm: inputValue?.trim(),
            });
        },
        [dispatchState, navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const dateArrowHandler = useCallback(
        (isRightArrow: boolean) => {
            setSelectedBlocks([]);
            lessonBlockRefs.current.forEach((ref) => {
                ref.setIsChecked(false);
            });
            lessonBlockRefs.current = new Map();
            if (state.values.teachingDays) {
                const teachingDayIndex = state.values.teachingDays?.findIndex(
                    (item) => item == state.values.dayIndex,
                );
                if (teachingDayIndex >= 0) {
                    if (isRightArrow) {
                        if (
                            teachingDayIndex !==
                            state.values.teachingDays.length - 1
                        ) {
                            dispatchState({
                                input: "dayIndex",
                                value:
                                    state.values.teachingDays[
                                        teachingDayIndex + 1
                                    ] ?? state.values.dayIndex + 1,
                            });
                        } else {
                            dispatchState({
                                input: "dayIndex",
                                value: state.values.teachingDays[0] ?? 0,
                            });
                        }
                    } else {
                        if (teachingDayIndex != 0) {
                            dispatchState({
                                input: "dayIndex",
                                value:
                                    state.values.teachingDays[
                                        teachingDayIndex - 1
                                    ] ?? state.values.dayIndex - 1,
                            });
                        } else {
                            dispatchState({
                                input: "dayIndex",
                                value:
                                    state.values.teachingDays.slice(-1)?.[0] ??
                                    4,
                            });
                        }
                    }
                }
            } else {
                if (isRightArrow) {
                    if (state.values.dayIndex != 4) {
                        dispatchState({
                            input: "dayIndex",
                            value: state.values.dayIndex + 1,
                        });
                    } else {
                        dispatchState({
                            input: "dayIndex",
                            value: 0,
                        });
                    }
                } else {
                    if (state.values.dayIndex != 0) {
                        dispatchState({
                            input: "dayIndex",
                            value: state.values.dayIndex - 1,
                        });
                    } else {
                        dispatchState({
                            input: "dayIndex",
                            value: 4,
                        });
                    }
                }
            }
        },
        [dispatchState, state.values.dayIndex, state.values.teachingDays],
    );

    const refreshHandler = useCallback(() => {
        const refreshData = () => {
            dispatchState({ input: "contentIsRendered", value: false });
            setSelectedBlocks([]);
            lessonBlockRefs.current.forEach((ref) => {
                ref.setIsChecked(false);
            });
            lessonBlockRefs.current = new Map();
            loadLessonBlocksQuery(
                {
                    first: LOAD_X_LESSON_BLOCKS,
                    searchTerm: state.values.searchTerm.trim(),
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    school: state.values.school,
                    profileId: profileId,
                    dayIndex: state.values.dayIndex,
                    skipLessonBlocks: false,
                    skipRegisterProgress: false,
                    skipLessonStages: true,
                },
                { fetchPolicy: "network-only" },
            );
            dispatchState({ input: "contentIsRendered", value: true });
        };
        if (registerUpdates.current.length > 0) {
            dispatchState({
                input: "leaveAlertAction",
                value: refreshData,
            });
        } else {
            refreshData();
        }
    }, [
        registerUpdates,
        dispatchState,
        loadLessonBlocksQuery,
        state.values.searchTerm,
        state.values.startingYear,
        state.values.block,
        state.values.school,
        state.values.dayIndex,
        profileId,
    ]);

    const renderRegisterProgress = useMemo(() => {
        if (
            registerProgressCounter.current.total !== undefined &&
            registerProgressCounter.current.marked !== undefined
        ) {
            const percentageProgress =
                registerProgressCounter.current.total > 0
                    ? 100 *
                      (registerProgressCounter.current.marked /
                          registerProgressCounter.current.total)
                    : 0;
            let trackColor = "emerald.400";
            if (percentageProgress < 50) {
                trackColor = "red.300";
            } else if (percentageProgress < 90) {
                trackColor = "warning.300";
            } else if (percentageProgress < 100) {
                trackColor = "primary.300";
            }

            return (
                <Box>
                    <Progress
                        _filledTrack={{ bg: trackColor }}
                        height="8"
                        value={percentageProgress}
                    />
                    <Box
                        alignItems="center"
                        alignSelf="center"
                        position="absolute"
                        top="1.5">
                        <Text>
                            {percentageProgress.toFixed(1)}% (
                            {registerProgressCounter.current.marked}/
                            {registerProgressCounter.current.total}) up-to-date
                        </Text>
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box>
                    <Progress height="8" value={0} />
                </Box>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        registerProgressCounter.current.total,
        registerProgressCounter.current.marked,
    ]);

    const [teachingDayTooltipVisible, setTeachingDayTooltipVisible] =
        useState(false);
    const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
    const {
        isOpen: lessonBlockOptionsActionsheetIsOpen,
        onClose: lessonBlockOptionsActionsheetOnClose,
        onOpen: lessonBlockOptionsActionsheetOnOpen,
    } = useDisclose();

    const lessonBlockRefs = useRef(new Map());

    const renderDayPicker = useMemo(() => {
        return (state.values.teachingDays?.length ?? 0) > 0 ? (
            <Center mx="-6" px="6">
                <Button.Group>
                    {(state.values.teachingDays?.length ?? 0) > 1 ? (
                        <Button
                            _hover={{ bg: "transparent", opacity: 0.8 }}
                            _pressed={{ bg: "transparent", opacity: 0.7 }}
                            leftIcon={
                                <ChevronLeftIcon
                                    size={Platform.OS === "web" ? "6" : "5"}
                                />
                            }
                            onPress={() => dateArrowHandler(false)}
                            px="2"
                            py="1"
                            variant="ghost"
                        />
                    ) : (
                        <></>
                    )}
                    <Center px="2" py="1" width="75px">
                        <Tooltip
                            isOpen={
                                teachingDayTooltipVisible &&
                                Boolean(state.values.teachingDays)
                            }
                            label={teachingDaysToString(
                                state.values.teachingDays,
                            )}
                            textAlign="center">
                            <Pressable
                                onHoverIn={() =>
                                    setTeachingDayTooltipVisible(true)
                                }
                                onHoverOut={() =>
                                    setTeachingDayTooltipVisible(false)
                                }>
                                <Text
                                    color="primary.600"
                                    fontSize={
                                        Platform.OS === "web" ? "xl" : "lg"
                                    }>
                                    {TEACHING_DAYS[
                                        state.values.dayIndex
                                    ].label.slice(0, 3)}
                                </Text>
                            </Pressable>
                        </Tooltip>
                    </Center>
                    {(state.values.teachingDays?.length ?? 0) > 1 ? (
                        <Button
                            _hover={{ bg: "transparent", opacity: 0.8 }}
                            _pressed={{ bg: "transparent", opacity: 0.7 }}
                            leftIcon={
                                <ChevronRightIcon
                                    size={Platform.OS === "web" ? "6" : "5"}
                                />
                            }
                            onPress={() => dateArrowHandler(true)}
                            px="2"
                            py="1"
                            variant="ghost"
                        />
                    ) : (
                        <></>
                    )}
                </Button.Group>
            </Center>
        ) : null;
    }, [
        state.values.teachingDays,
        state.values.dayIndex,
        teachingDayTooltipVisible,
        dateArrowHandler,
    ]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mb="4" mx="-6" px="6" space="4">
                <HStack justifyContent="space-between">
                    <HStack space="4">
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const value = parseInt(itemValue);

                                if (value > state.values.startingYear) {
                                    dispatchState({
                                        input: "block",
                                        value: 1,
                                    });
                                    navigation.setParams({
                                        block: 1,
                                    });
                                }

                                dispatchState({
                                    input: "startingYear",
                                    value: value,
                                });
                                navigation.setParams({
                                    startingYear: value,
                                });
                            }}
                            placeholder="Select year"
                            selectedValue={String(state.values.startingYear)}
                            width="40">
                            {startingYears.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={String(item.value)}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "block",
                                    value: parseInt(itemValue),
                                });
                                navigation.setParams({
                                    block: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select block"
                            selectedValue={String(state.values.block)}
                            width="40">
                            {BLOCKS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            color={
                                state.values.school
                                    ? "surface.900"
                                    : "muted.400"
                            }
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const parsedValue =
                                    itemValue !== "ANY" ? itemValue : undefined;
                                dispatchState({
                                    input: "school",
                                    value: parsedValue,
                                });
                                navigation.setParams({
                                    school: parsedValue,
                                });
                            }}
                            placeholder="Select school"
                            selectedValue={state.values.school ?? "ANY"}
                            width="56">
                            <Select.Item
                                key={"ANY"}
                                actionSheetLabel={"Any school"}
                                value={"ANY"}
                            />
                            {teacherSchools?.map((item) => {
                                return (
                                    <Select.Item
                                        key={item?.name}
                                        actionSheetLabel={
                                            titleCaseConverter(item?.name) ?? ""
                                        }
                                        value={item?.name ?? ""}
                                    />
                                );
                            }) ??
                                allSchools.map((item) => {
                                    return (
                                        <Select.Item
                                            key={item?.node?.name}
                                            actionSheetLabel={
                                                titleCaseConverter(
                                                    item?.node?.name,
                                                ) ?? ""
                                            }
                                            value={item?.node?.name ?? ""}
                                        />
                                    );
                                })}
                        </Select>
                        {userIsTeacher ? (
                            <>
                                {Platform.OS === "web" && windowWidth > 1000 ? (
                                    <Box flex={1} maxWidth="300">
                                        <SearchBar
                                            inputClearHandler={
                                                inputClearHandler
                                            }
                                            inputOnChangeHandler={
                                                inputChangeHandler
                                            }
                                            inputSearchHandler={
                                                inputChangeHandler
                                            }
                                            placeholderText={
                                                "Search by pupil name"
                                            }
                                            searchText={state.values.searchTerm}
                                            showSearchIcon
                                        />
                                    </Box>
                                ) : null}
                                <Button
                                    bg="surface.400"
                                    colorScheme="surface"
                                    height="10"
                                    leftIcon={<RestartIcon size="5" />}
                                    onPress={refreshHandler}
                                    width="10"
                                />
                            </>
                        ) : null}
                    </HStack>
                    {!userIsTeacher ? (
                        <HStack space="4">
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<AddCircleIcon size="md" />}
                                onPress={() => {
                                    dispatchState({
                                        input: "selectedSchoolOnModal",
                                        value:
                                            state.values.school ??
                                            teacherSchools?.[0]?.name ??
                                            allSchools[0]?.node?.name,
                                    });
                                    dispatchState({
                                        input: "selectSchoolModalIsOpen",
                                        value: true,
                                    });
                                }}
                                px="4"
                                shadow={1}>
                                Add Lesson Block
                            </Button>
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<AddCircleIcon size="md" />}
                                onPress={() => {
                                    dispatchState({
                                        input: "selectedSchoolOnModal",
                                        value:
                                            state.values.school ??
                                            teacherSchools?.[0]?.name ??
                                            allSchools[0]?.node?.name,
                                    });
                                    dispatchState({
                                        input: "selectSchoolModalIsOpen",
                                        value: true,
                                    });
                                    dispatchState({
                                        input: "isAddLessonBreakModal",
                                        value: true,
                                    });
                                }}
                                px="4"
                                shadow={1}>
                                Add Break
                            </Button>
                        </HStack>
                    ) : null}
                </HStack>
                {!userIsTeacher ? (
                    <HStack justifyContent="space-between">
                        <HStack space="4" width="400">
                            <Box flex={1}>
                                <SearchBar
                                    inputClearHandler={inputClearHandler}
                                    inputOnChangeHandler={inputChangeHandler}
                                    inputSearchHandler={inputChangeHandler}
                                    placeholderText={"Search by pupil name"}
                                    searchText={state.values.searchTerm}
                                    showSearchIcon
                                />
                            </Box>
                            <Button
                                bg="surface.400"
                                colorScheme="surface"
                                height="10"
                                leftIcon={<RestartIcon size="5" />}
                                onPress={refreshHandler}
                                width="10"
                            />
                        </HStack>
                        <HStack space="4">
                            <PresenceTransition
                                animate={{
                                    opacity: 1,
                                    scale: 1,
                                    transition: {
                                        duration: 250,
                                    },
                                }}
                                initial={{
                                    opacity: 0,
                                    scale: 0,
                                }}
                                visible={selectedBlocks.length > 0}>
                                <Button
                                    _text={{ fontSize: "17" }}
                                    colorScheme="surface"
                                    leftIcon={
                                        <Center size="5">
                                            <OptionsIcon
                                                color="surface.100"
                                                size="5"
                                            />
                                        </Center>
                                    }
                                    onPress={() =>
                                        lessonBlockOptionsActionsheetOnOpen()
                                    }
                                    pr="4"
                                    shadow={1}>
                                    {`View Options (${selectedBlocks.length})`}
                                </Button>
                            </PresenceTransition>
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<CopyIcon size="md" />}
                                onPress={copyDataToClipboard}
                                px="4"
                                shadow={1}>
                                Copy Table to Clipboard
                            </Button>
                        </HStack>
                    </HStack>
                ) : null}
                {Platform.OS === "web" ? renderRegisterProgress : null}
                {renderDayPicker}
            </VStack>
        );
    }, [
        allSchools,
        copyDataToClipboard,
        dispatchState,
        inputChangeHandler,
        inputClearHandler,
        lessonBlockOptionsActionsheetOnOpen,
        navigation,
        refreshHandler,
        renderDayPicker,
        renderRegisterProgress,
        selectedBlocks.length,
        startingYears,
        state.values.block,
        state.values.school,
        state.values.searchTerm,
        state.values.startingYear,
        teacherSchools,
        userIsTeacher,
        windowWidth,
    ]);

    const renderLessonBlockOptionsActionsheet = useMemo(() => {
        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={lessonBlockOptionsActionsheetIsOpen}
                justifyContent="center"
                onClose={lessonBlockOptionsActionsheetOnClose}
                size="lg">
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%">
                    <Text
                        alignSelf="center"
                        fontSize="xl"
                        fontWeight="bold"
                        py="4">
                        {`Lesson Block Actions (${selectedBlocks.length})`}
                    </Text>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <AccountsIcon color="primary.500" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "TEACHER",
                                lessonBlockIds: selectedBlocks,
                                selectedProfileId: profileId,
                                selectedProfileFullName: profileFirstName
                                    ? getFullName(
                                          profileFirstName,
                                          profileLastName,
                                      )
                                    : undefined,
                                onUpdate: () => {
                                    refreshHandler();
                                },
                            });
                        }}>
                        {`Change block${
                            selectedBlocks.length > 1 ? "s" : ""
                        } to different teacher`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <CalendarIcon color="primary.500" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            setDayPickerIsOpen(true);
                        }}>
                        {`Change block${
                            selectedBlocks.length > 1 ? "s" : ""
                        } to different day`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <UserClockIcon color="primary.600" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "TEACHER_AND_DAY",
                                lessonBlockIds: selectedBlocks,
                                selectedProfileId: profileId,
                                selectedProfileFullName: profileFirstName
                                    ? getFullName(
                                          profileFirstName,
                                          profileLastName,
                                      )
                                    : undefined,
                                onUpdate: () => {
                                    refreshHandler();
                                },
                            });
                        }}>
                        {`Change block${
                            selectedBlocks.length > 1 ? "s" : ""
                        } to different teacher and day`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <UserSlashIcon color="error.500" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            updateLessonBlockDetails(
                                {
                                    lessonBlockIds: selectedBlocks,
                                    pupilId: null,
                                    archiveBlock: true,
                                },
                                undefined,
                                () => {
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            );
                        }}>
                        {`Remove assigned pupil from block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <EditIcon color="secondary.600" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "NOTES",
                                lessonBlockIds: selectedBlocks,
                                onUpdate: () => {
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            });
                        }}>
                        {`Add/update note for lesson block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                {removeLessonBlocksInFlight ? (
                                    <Spinner color="surface.800" size="sm" />
                                ) : (
                                    <TrashIcon color="surface.800" size="5" />
                                )}
                            </Center>
                        }
                        onPress={() => {
                            removeLessonBlocks(
                                selectedBlocks,
                                lessonBlocksConnectionId,
                                () => {
                                    lessonBlockOptionsActionsheetOnClose();
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            );
                        }}>
                        {`Delete lesson block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        lessonBlockOptionsActionsheetIsOpen,
        lessonBlockOptionsActionsheetOnClose,
        lessonBlocksConnectionId,
        profileFirstName,
        profileId,
        profileLastName,
        refreshHandler,
        removeLessonBlocks,
        removeLessonBlocksInFlight,
        selectedBlocks,
        showUpdateBlockDetailsModalHandler,
        updateLessonBlockDetails,
    ]);

    const [dayPickerIsOpen, setDayPickerIsOpen] = useState(false);

    return (
        <Box flex={1}>
            {renderHeader}
            {loadLessonBlocksQueryReference != null ? (
                <Suspense
                    fallback={
                        <LoadingBlobs>Loading Registers...</LoadingBlobs>
                    }>
                    <TeacherRegistersContent
                        {...props}
                        dataProvider={dataProvider}
                        lessonBlockRefs={lessonBlockRefs}
                        loadLessonBlocksQueryReference={
                            loadLessonBlocksQueryReference
                        }
                        refreshHandler={refreshHandler}
                        selectedBlocks={selectedBlocks}
                        setCopyDataToClipboard={setCopyDataToClipboard}
                        setDataProvider={setDataProvider}
                        setSelectedBlocks={setSelectedBlocks}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Registers...</LoadingBlobs>
            )}
            {renderLessonBlockOptionsActionsheet}
            {updateScheduledLessonBlockTime ? (
                <DayPickerModal
                    hideModal={() => setDayPickerIsOpen(false)}
                    initialLessonDay={state.values.dayIndex}
                    lessonBlockIds={selectedBlocks}
                    onUpdate={() => {
                        setSelectedBlocks([]);
                        lessonBlockRefs.current.forEach((ref) => {
                            ref.setIsChecked(false);
                        });
                    }}
                    showModal={dayPickerIsOpen}
                    updateScheduledLessonBlockTime={
                        updateScheduledLessonBlockTime
                    }
                    updateScheduledLessonBlockTimeInFlight={
                        updateScheduledLessonBlockTimeInFlight ?? false
                    }
                />
            ) : null}
        </Box>
    );
};

export default React.memo(TeacherRegisters);
