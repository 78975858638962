/**
 * @generated SignedSource<<9b59e1b8326f489ad4d922a1824a0ba5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateScheduledLessonBlockTimeInput = {
  clientMutationId?: string | null;
  dayIndex: number;
  hours?: number | null;
  lessonBlockIds: ReadonlyArray<string | null>;
  minutes?: number | null;
  overrideRescheduledLessons?: boolean | null;
};
export type UpdateScheduledLessonBlockTimeMutation$variables = {
  block?: number | null;
  input: UpdateScheduledLessonBlockTimeInput;
  startingYear?: number | null;
};
export type UpdateScheduledLessonBlockTimeMutation$data = {
  readonly updateScheduledLessonBlockTime: {
    readonly errors: any | null;
    readonly lessonBlocks: ReadonlyArray<{
      readonly id: string;
      readonly lessonDay: number | null;
      readonly lessonTime: string | null;
      readonly lessons: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly scheduledTimestamp: string | null;
          } | null;
        } | null>;
      };
      readonly teacher: {
        readonly id: string;
        readonly teachingDays: ReadonlyArray<number | null> | null;
      } | null;
    } | null> | null;
    readonly success: boolean | null;
  } | null;
};
export type UpdateScheduledLessonBlockTimeMutation = {
  response: UpdateScheduledLessonBlockTimeMutation$data;
  variables: UpdateScheduledLessonBlockTimeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lessonTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileNode",
  "kind": "LinkedField",
  "name": "teacher",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "block",
          "variableName": "block"
        },
        {
          "kind": "Variable",
          "name": "startingYear",
          "variableName": "startingYear"
        }
      ],
      "kind": "ScalarField",
      "name": "teachingDays",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledTimestamp",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduledLessonBlockTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpdateScheduledLessonBlockTimePayload",
        "kind": "LinkedField",
        "name": "updateScheduledLessonBlockTime",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonBlockNode",
            "kind": "LinkedField",
            "name": "lessonBlocks",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeConnection",
                "kind": "LinkedField",
                "name": "lessons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateScheduledLessonBlockTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpdateScheduledLessonBlockTimePayload",
        "kind": "LinkedField",
        "name": "updateScheduledLessonBlockTime",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LessonBlockNode",
            "kind": "LinkedField",
            "name": "lessonBlocks",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonNodeConnection",
                "kind": "LinkedField",
                "name": "lessons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LessonNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LessonNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "633d901d7cceff787117b6eeb995cc60",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduledLessonBlockTimeMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduledLessonBlockTimeMutation(\n  $input: UpdateScheduledLessonBlockTimeInput!\n  $startingYear: Int\n  $block: Int\n) {\n  updateScheduledLessonBlockTime(input: $input) {\n    success\n    errors\n    lessonBlocks {\n      id\n      lessonDay\n      lessonTime\n      teacher {\n        id\n        teachingDays(startingYear: $startingYear, block: $block)\n      }\n      lessons {\n        edges {\n          node {\n            scheduledTimestamp\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e21012ee858a53290147fed75a8eb8c";

export default node;
