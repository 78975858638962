import React, { useMemo } from "react";
import type { ReactElement } from "react";

import AccountStatus from "../Accounts/AccountStatus";
import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import type { LoadSchools_query_schools$data } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchools_query_schools.graphql";

import {
    combineAddress,
    schoolTypeConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<
        NonNullable<
            NonNullable<LoadSchools_query_schools$data["schools"]>["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressSchool: (schoolId?: string) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const SchoolTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressSchool,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        return [
            {
                data: titleCaseConverter(data.name) ?? "",
                onPress: () => onPressSchool(data.id ?? undefined),
                tooltipLabel: data.mainOfficeContact?.addressFirstLine
                    ? combineAddress(
                          data?.mainOfficeContact?.addressFirstLine,
                          data?.mainOfficeContact?.addressSecondLine,
                          data?.mainOfficeContact?.city,
                          data?.mainOfficeContact?.postcode,
                      )
                    : undefined,
            },
            {
                data: schoolTypeConverter(data.schoolType),
            },
            {
                data:
                    getFullName(
                        data.headTeacher?.user.firstName,
                        data.headTeacher?.user.lastName,
                    ) ??
                    data.headTeacher?.user.email ??
                    "",
                tooltipLabel:
                    data.headTeacher?.user.firstName &&
                    (data.headTeacher?.user.email ||
                        data?.headTeacher?.phoneNumber)
                        ? `${data?.headTeacher?.user.email ?? ""}${
                              data?.headTeacher?.user.email &&
                              data?.headTeacher?.phoneNumber
                                  ? "\n"
                                  : ""
                          }${data?.headTeacher?.phoneNumber ?? ""}`
                        : undefined,
            },
            {
                data:
                    getFullName(
                        data.mainOfficeContact?.user.firstName,
                        data.mainOfficeContact?.user.lastName,
                    ) ??
                    data.mainOfficeContact?.user.email ??
                    "",
                tooltipLabel:
                    data.mainOfficeContact?.user.firstName &&
                    (data.mainOfficeContact?.user.email ||
                        data?.mainOfficeContact?.phoneNumber)
                        ? `${data?.mainOfficeContact?.user.email ?? ""}${
                              data?.mainOfficeContact?.user.email &&
                              data?.mainOfficeContact?.phoneNumber
                                  ? "\n"
                                  : ""
                          }${data?.mainOfficeContact?.phoneNumber ?? ""}`
                        : undefined,
            },
            {
                data: data?.numberOfActiveTeachers ?? 0,
            },
            {
                data: data?.numberOfFilledLessonSlots ?? 0,
            },
            {
                data: data?.numberOfFreeLessonSlots ?? 0,
            },
            {
                data: data?.numberOfPupilsOnWaitingList ?? 0,
            },
            {
                data: (
                    <AccountStatus
                        isTransparent
                        status={data.archived ? "ARCHIVED" : "ACTIVE"}
                        textProps={{
                            fontFamily: "Poppins-Light",
                        }}
                    />
                ),
                onPress: () => onPressSchool(data.id ?? undefined),
            },
        ];
    }, [
        data.name,
        data.mainOfficeContact?.addressFirstLine,
        data.mainOfficeContact?.addressSecondLine,
        data.mainOfficeContact?.city,
        data.mainOfficeContact?.postcode,
        data.mainOfficeContact?.user.firstName,
        data.mainOfficeContact?.user.lastName,
        data.mainOfficeContact?.user.email,
        data.mainOfficeContact?.phoneNumber,
        data.schoolType,
        data.headTeacher?.user.firstName,
        data.headTeacher?.user.lastName,
        data.headTeacher?.user.email,
        data.headTeacher?.phoneNumber,
        data?.numberOfActiveTeachers,
        data?.numberOfFilledLessonSlots,
        data?.numberOfFreeLessonSlots,
        data?.numberOfPupilsOnWaitingList,
        data.archived,
        data.id,
        onPressSchool,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default SchoolTableRow;
