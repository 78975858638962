import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    useMemo,
    Suspense,
} from "react";
import type {
    ReactElement,
    ComponentProps,
    Dispatch,
    SetStateAction,
    MutableRefObject,
} from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    VStack,
    HStack,
    Button,
    Text,
    useTheme,
    useToast,
    PresenceTransition,
    Center,
    Spinner,
    Checkbox,
    useDisclose,
} from "native-base";
import { useWindowDimensions } from "react-native";
import { DayTimePickerModal } from "react-native-paper-dates";
import {
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
    useMutation,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription, RecordSourceSelectorProxy } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import type {
    ReducerValues as SchoolScreenReducerValues,
    ReducerTypes as SchoolScreenReducerTypes,
} from "../../screens/schools/SchoolScreen";
import type { NavigationProps as SchoolScreenNavigationProps } from "../../screens/schools/SchoolScreen";
import { schoolLessonsTableCsvConverter } from "../../utils/tableCsvConverters";
import LessonBlockTableRow from "../ListItems/LessonBlockTableRow";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import DayPickerModal from "pianofunclub-shared/components/Modals/DayPickerModal";
import UpdateLessonBlockDetailsModal from "pianofunclub-shared/components/Modals/UpdateLessonBlockDetailsModal";
import UpdateLessonBlockTeacherOrPupilModal from "pianofunclub-shared/components/Modals/UpdateLessonBlockTeacherOrPupilModal";
import Actionsheet from "pianofunclub-shared/components/NativeBaseExtended/Actionsheet";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    AccountsIcon,
    AddCircleIcon,
    CalendarIcon,
    CopyIcon,
    EditIcon,
    OptionsIcon,
    RestartIcon,
    TrashIcon,
    UserClockIcon,
    UserSlashIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type {
    AddNewLessonBlockMutation,
    AddNewLessonBlockMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/AddNewLessonBlockMutation.graphql";
import type {
    LoadLessonBlocks_query_lessonBlocks$key,
    LoadLessonBlocks_query_lessonBlocks$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocks_query_lessonBlocks.graphql";
import type { LoadLessonBlocksPaginationQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksPaginationQuery.graphql";
import type { LoadLessonBlocksQuery } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import type {
    RemoveLessonBlocksMutation,
    RemoveLessonBlocksMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/RemoveLessonBlocksMutation.graphql";
import type {
    UpdateLessonBlockDetailsMutation,
    UpdateLessonBlockDetailsMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateLessonBlockDetailsMutation.graphql";
import type {
    UpdateScheduledLessonBlockTimeMutation,
    UpdateScheduledLessonBlockTimeMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateScheduledLessonBlockTimeMutation.graphql";
import { add_new_lesson_block } from "pianofunclub-shared/relay/graphql/registers/AddNewLessonBlock";
import {
    load_lesson_blocks,
    load_lesson_blocks_pagination,
} from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import { remove_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/RemoveLessonBlocks";
import { update_lesson_block_details } from "pianofunclub-shared/relay/graphql/registers/UpdateLessonBlockDetails";
import { update_scheduled_lesson_block_time } from "pianofunclub-shared/relay/graphql/registers/UpdateScheduledLessonBlockTime";
import { lessonBlockConnectionUpdater } from "pianofunclub-shared/relay/updaters";

import type { AccountType, Mutable } from "pianofunclub-shared/types";
import { BLOCKS, TEACHING_DAYS } from "pianofunclub-shared/utils/constants";
import { getScaledWindowDimension } from "pianofunclub-shared/utils/converters";
import {
    getDefaultStartingYearAndStartingYearOptions,
    getOrderBy,
    sortIconExtractor,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

export type LessonBlockData = NonNullable<
    LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
>["edges"][0];

interface Props extends VStackProps {
    dispatchState: Dispatch<
        Action<SchoolScreenReducerValues, SchoolScreenReducerTypes>
    >;
    loadLessonBlocksQueryReference:
        | PreloadedQuery<LoadLessonBlocksQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: SchoolScreenNavigationProps;
    refreshHandler: (onRefresh?: () => void) => void;
    schoolId: string;
    schoolName: string;
    sideBarWidth: number;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<SchoolScreenReducerValues>;
}

interface ContentProps extends Props {
    dataProvider: DataProvider;
    dayPickerIsOpen: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lessonBlockRefs: MutableRefObject<Map<any, any>>;
    loadLessonBlocksQueryReference: PreloadedQuery<
        LoadLessonBlocksQuery,
        Record<string, unknown>
    >;
    selectedBlocks: string[];
    setCopyDataToClipboard: Dispatch<
        SetStateAction<(() => void | undefined) | undefined>
    >;
    setDataProvider: Dispatch<SetStateAction<DataProvider>>;
    setDayPickerIsOpen: Dispatch<SetStateAction<boolean>>;
    setLessonBlockIds: Dispatch<SetStateAction<string[]>>;
    setSelectedBlocks: Dispatch<SetStateAction<string[]>>;
    showUpdateBlockDetailsModalHandler: (variables: {
        keepFree?: boolean | undefined;
        lessonBlockCursor?: string | undefined;
        lessonBlockIds: string[];
        onUpdate?: () => void;
        school?: string | undefined;
        selectedProfileFullName?: string | undefined;
        selectedProfileId?: string | undefined;
        type: string;
    }) => void;
}

const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [0.3, 1.2, 1, 0.8, 0.8, 0.8, 1.1, 0.6];
export const LOAD_X_LESSON_BLOCKS = 18;

const SchoolLessonsContent = (props: ContentProps): ReactElement => {
    const {
        dataProvider,
        dayPickerIsOpen,
        dispatchState,
        lessonBlockRefs,
        loadLessonBlocksQueryReference,
        navigation,
        refreshHandler,
        schoolName,
        selectedBlocks,
        setCopyDataToClipboard,
        setDataProvider,
        setDayPickerIsOpen,
        setLessonBlockIds,
        setSelectedBlocks,
        showUpdateBlockDetailsModalHandler,
        sideBarWidth,
        state,
    } = props;

    const { user } = useAuth();

    const data = usePreloadedQuery(
        load_lesson_blocks,
        loadLessonBlocksQueryReference,
    );

    const {
        data: lessonBlocksData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadLessonBlocksPaginationQuery,
        LoadLessonBlocks_query_lessonBlocks$key
    >(load_lesson_blocks_pagination, data);

    const lessonStages = useMemo(() => {
        // need to sort the lesson stages
        if (data.lessonStages?.edges) {
            const unsortedLessonStages = data.lessonStages.edges as Mutable<
                typeof data.lessonStages.edges
            >;
            return unsortedLessonStages
                .slice()
                .sort((a, b) => (a?.node?.stage ?? 0) - (b?.node?.stage ?? -1));
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.lessonStages?.edges]);

    const currentStartingYear = useMemo(() => {
        return (
            user?.profile?.profileGroup?.currentStartingYear ??
            getDefaultStartingYearAndStartingYearOptions()[0]
        );
    }, [user?.profile?.profileGroup?.currentStartingYear]);

    useEffect(() => {
        dispatchState({
            input: "lessonBlocksConnectionId",
            value: lessonBlocksData.lessonBlocks?.__id,
        });
        if (
            lessonBlocksData.lessonBlocks?.edges &&
            lessonBlocksData.lessonBlocks.edges.length > 0
        ) {
            setDataProvider(
                dataProvider.cloneWithRows(
                    lessonBlocksData.lessonBlocks.edges as Mutable<
                        typeof lessonBlocksData.lessonBlocks.edges
                    >,
                ),
            );
            setLessonBlockIds(
                lessonBlocksData.lessonBlocks.edges.map(
                    (item) => item?.node?.id ?? "",
                ),
            );
            // if there is no data, create a fresh DataProvider
        } else {
            setDataProvider(
                new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            );
            setLessonBlockIds([]);
        }

        setCopyDataToClipboard(() => () => {
            Clipboard.setStringAsync(
                schoolLessonsTableCsvConverter(
                    lessonBlocksData.lessonBlocks?.edges ?? [],
                ),
            );
            toast.show({
                render: ({ id }: { id: string }) => (
                    <ToastAlert
                        id={id}
                        status="success"
                        title={"Copied Data to Clipboard"}
                        toast={toast}
                    />
                ),
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonBlocksData.lessonBlocks?.edges]);

    const { scale, width: windowWidth } = useWindowDimensions();

    const [layoutProvider, setLayoutProvider] = useState(
        new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = getScaledWindowDimension(
                            windowWidth,
                            scale,
                        );
                        dim.height = 40;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        ),
    );

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_LESSON_BLOCKS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const { colors } = useTheme();

    const refetchLessonBlocks = useCallback(
        (variables: {
            block: number;
            dayIndex?: number;
            orderBy?: string;
            searchTerm?: string;
            startingYear: number;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const fullVariables = {
                ...variables,
                first: LOAD_X_LESSON_BLOCKS,
                school: schoolName,
                profileId: "",
                skipLessonBlocks: false,
                skipRegisterProgress: true,
                skipLessonStages: false,
            };

            const subscription = fetchQuery<LoadLessonBlocksQuery>(
                environment,
                load_lesson_blocks,
                fullVariables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    setSelectedBlocks([]);
                    lessonBlockRefs.current.forEach((ref) => {
                        ref.setIsChecked(false);
                    });
                    lessonBlockRefs.current = new Map();
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(fullVariables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatchState, environment, refresh, schoolName],
    );

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        dispatchState({
            input: "subscription",
            value: refetchLessonBlocks({
                searchTerm: state.values.searchTerm.trim(),
                startingYear: state.values.startingYear,
                block: state.values.block,
                dayIndex: state.values.dayIndex,
                orderBy: state.values.orderBy,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.startingYear,
        state.values.block,
        state.values.dayIndex,
        state.values.orderBy,
    ]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 4) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply sorting if search text is blank
            const orderBy =
                state.values.searchTerm.trim() === ""
                    ? "lessonDay,lessonTime"
                    : undefined;

            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchLessonBlocks({
                    searchTerm: state.values.searchTerm.trim(),
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                    dayIndex: state.values.dayIndex,
                    orderBy: orderBy,
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        refreshHandler(() => {
            setSelectedBlocks([]);
            lessonBlockRefs.current.forEach((ref) => {
                ref.setIsChecked(false);
            });
            lessonBlockRefs.current = new Map();
            setDataProvider(
                new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.values.currentPageIndex]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }
        // this allows the RLV to resize when the window resizes
        setLayoutProvider(
            new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = windowWidth;
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        );
    }, [windowWidth, sideBarWidth]);

    const toast = useToast();

    const [
        commitUpdateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
    ] = useMutation<UpdateScheduledLessonBlockTimeMutation>(
        update_scheduled_lesson_block_time,
    );

    const updateScheduledLessonBlockTime = useCallback(
        (
            lessonBlockIds: string[],
            dayIndex: number,
            hours?: number,
            minutes?: number,
            onComplete?: () => void,
        ) => {
            const updateScheduledLessonBlockTimeConfig = {
                variables: {
                    input: {
                        lessonBlockIds,
                        dayIndex,
                        hours,
                        minutes,
                    },
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                onCompleted: (
                    response: UpdateScheduledLessonBlockTimeMutation$data,
                ) => {
                    if (response?.updateScheduledLessonBlockTime?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Rescheduled lesson block${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't reschedule lesson block${
                                        lessonBlockIds.length > 1 ? "s" : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    onComplete?.();
                },
            };

            commitUpdateScheduledLessonBlockTime(
                updateScheduledLessonBlockTimeConfig,
            );
        },
        [
            commitUpdateScheduledLessonBlockTime,
            state.values.block,
            state.values.startingYear,
            toast,
        ],
    );

    const [commitUpdateLessonBlockDetails, updateLessonBlockDetailsInFlight] =
        useMutation<UpdateLessonBlockDetailsMutation>(
            update_lesson_block_details,
        );

    const updateLessonBlockDetails = useCallback(
        (
            variables: {
                instrument?: string | null;
                keepFree?: boolean;
                lessonBlockIds: string[];
                pupilId?: string | null;
                staffNoteForTeacher?: string;
                stageId?: string | null;
                teacherId?: string | null;
                teacherNoteForStaff?: string;
            },
            lessonBlockCursor?: string,
            onComplete?: () => void,
        ) => {
            const updateLessonBlockDetailsConfig = {
                variables: {
                    connections: state.values.lessonBlocksConnectionId
                        ? [state.values.lessonBlocksConnectionId]
                        : [],
                    input: variables,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                updater: (store: RecordSourceSelectorProxy) =>
                    lessonBlockConnectionUpdater(
                        store,
                        state.values.lessonBlocksConnectionId,
                        lessonBlockCursor,
                    ),
                onCompleted: (
                    response: UpdateLessonBlockDetailsMutation$data,
                ) => {
                    if (response?.updateLessonBlockDetails?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Updated lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't update lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLessonBlockDetails(updateLessonBlockDetailsConfig);
        },
        [
            commitUpdateLessonBlockDetails,
            state.values.block,
            state.values.lessonBlocksConnectionId,
            state.values.startingYear,
            toast,
        ],
    );

    const [commitAddNewLessonBlock, addNewLessonBlockInFlight] =
        useMutation<AddNewLessonBlockMutation>(add_new_lesson_block);

    const addNewLessonBlock = useCallback(
        (
            startingYear: number,
            block: number,
            dayIndex: number,
            hours: number,
            minutes: number,
            type: string,
        ) => {
            const addNewLessonBlockConfig = {
                variables: {
                    connections: lessonBlocksData.lessonBlocks?.__id
                        ? [lessonBlocksData.lessonBlocks.__id]
                        : [],
                    input: {
                        school: schoolName,
                        startingYear: startingYear,
                        block: block,
                        dayIndex: dayIndex,
                        hours: hours,
                        minutes: minutes,
                        type: type,
                    },
                    startingYear: startingYear,
                    block: block,
                },
                onCompleted: (response: AddNewLessonBlockMutation$data) => {
                    dispatchState({
                        input: "showAddLessonBlockModal",
                        value: false,
                    });
                    if (response?.addNewLessonBlock?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Added new lesson block"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't add new lesson block"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitAddNewLessonBlock(addNewLessonBlockConfig);
        },
        [
            commitAddNewLessonBlock,
            dispatchState,
            lessonBlocksData.lessonBlocks?.__id,
            schoolName,
            toast,
        ],
    );

    const onPressProfile = useCallback(
        (profileId?: string | null, accountType?: AccountType | null) => {
            if (profileId && accountType) {
                navigation.push("Account", {
                    profileId: profileId,
                    accountType: accountType,
                    block: state.values.block,
                    startingYear: state.values.startingYear,
                });
            }
        },
        [navigation, state.values.block, state.values.startingYear],
    );

    const checkboxChangeHandler = useCallback(
        (blockId: string, isSelected: boolean) => {
            if (isSelected) {
                setSelectedBlocks((blocks) => blocks.concat(blockId));
            } else {
                setSelectedBlocks((blocks) =>
                    blocks.filter((id) => id !== blockId),
                );
            }
        },
        [setSelectedBlocks],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: LessonBlockData,
            index: number,
        ): ReactElement | null => {
            if (!data?.node) {
                return null;
            }

            return (
                <Box
                    borderColor={TABLE_BORDER_COLOR}
                    borderLeftWidth={TABLE_BORDER_WIDTH}>
                    <LessonBlockTableRow
                        ref={(ref) => {
                            if (
                                ref &&
                                data.node?.id &&
                                !lessonBlockRefs.current.get(data.node.id)
                            ) {
                                lessonBlockRefs.current.set(data.node.id, ref);
                            }
                        }}
                        cellProps={{
                            hideTopBorder: index === 0,
                            pressableTextProps: {
                                fontFamily: "Poppins-Regular",
                            },
                            textProps: {
                                fontSize: "md",
                                textAlign: "center",
                                fontFamily: "Poppins-Light",
                            },
                            placeholderTextProps: { color: "muted.400" },
                            px: 2,
                        }}
                        checkboxChangeHandler={checkboxChangeHandler}
                        currentStartingYear={currentStartingYear}
                        cursor={data.cursor}
                        data={data.node}
                        flexArray={FLEX_ARRAY}
                        onPressProfile={onPressProfile}
                        rowHeight={10}
                        selectedStartingYear={state.values.startingYear}
                        showUpdateBlockDetailsModalHandler={
                            showUpdateBlockDetailsModalHandler
                        }
                        tableBorderColor={TABLE_BORDER_COLOR}
                        tableBorderWidth={TABLE_BORDER_WIDTH}
                        updateScheduledLessonBlockTime={
                            updateScheduledLessonBlockTime
                        }
                    />
                </Box>
            );
        },
        [
            checkboxChangeHandler,
            currentStartingYear,
            lessonBlockRefs,
            onPressProfile,
            showUpdateBlockDetailsModalHandler,
            state.values.startingYear,
            updateScheduledLessonBlockTime,
        ],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    const renderListEmptyBanner = useCallback(() => {
        return (
            <ListEmptyBanner
                explainer={
                    state.values.searchTerm
                        ? "No lessons found..."
                        : "No lessons in this block"
                }>
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, [state.values.searchTerm]);

    const renderRefetchIndicator = useCallback(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="190" />
        ) : null;
    }, [state.values.isRefetching]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.values.contentIsRendered || !state.values.block) {
        return <LoadingBlobs>Loading Lessons...</LoadingBlobs>;
    }

    return (
        <>
            {dataProvider.getSize() > 0 ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={dataProvider}
                    layoutProvider={layoutProvider}
                    onEndReached={onEndReached}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                lessonBlocksData.lessonBlocks?.edges.length &&
                                !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : (
                renderListEmptyBanner()
            )}
            {renderRefetchIndicator()}
            <DayTimePickerModal
                animationType="fade"
                dayIndex={(state.values.dayIndex ?? 0) + 1}
                hours={9}
                isLoading={addNewLessonBlockInFlight}
                label="Add Lesson Block"
                locale="en"
                minutes={0}
                onConfirm={(params) => {
                    addNewLessonBlock(
                        state.values.startingYear,
                        state.values.block,
                        params.dayIndex,
                        params.hours,
                        params.minutes,
                        "LESSON",
                    );
                }}
                onDismiss={() =>
                    dispatchState({
                        input: "showAddLessonBlockModal",
                        value: false,
                    })
                }
                saveLabel="Add"
                uppercase={false}
                visible={state.values.showAddLessonBlockModal}
            />
            <UpdateLessonBlockDetailsModal
                detailType={
                    state.values.updateBlockDetailsModalInfo?.type as
                        | "INSTRUMENT"
                        | "STAGE"
                        | undefined
                }
                hideModal={() => {
                    dispatchState({
                        input: "updateBlockDetailsModalInfo",
                        value: undefined,
                    });
                }}
                lessonBlockCursor={
                    state.values.updateBlockDetailsModalInfo?.cursor
                }
                lessonBlockIds={state.values.updateBlockDetailsModalInfo?.ids}
                lessonStagesData={lessonStages}
                onUpdate={state.values.updateBlockDetailsModalInfo?.onUpdate}
                showModal={
                    (state.values.updateBlockDetailsModalInfo?.ids ?? false) &&
                    (state.values.updateBlockDetailsModalInfo?.ids?.length ??
                        0) > 0 &&
                    (state.values.updateBlockDetailsModalInfo?.type ===
                        "INSTRUMENT" ||
                        state.values.updateBlockDetailsModalInfo?.type ===
                            "STAGE" ||
                        state.values.updateBlockDetailsModalInfo?.type ===
                            "NOTES")
                }
                updateLessonBlockDetails={updateLessonBlockDetails}
                updateLessonBlockDetailsInFlight={
                    updateLessonBlockDetailsInFlight
                }
            />
            {updateScheduledLessonBlockTime ? (
                <DayPickerModal
                    hideModal={() => setDayPickerIsOpen(false)}
                    initialLessonDay={state.values.dayIndex}
                    lessonBlockIds={selectedBlocks}
                    onUpdate={() => {
                        setSelectedBlocks([]);
                        lessonBlockRefs.current.forEach((ref) => {
                            ref.setIsChecked(false);
                        });
                    }}
                    showModal={dayPickerIsOpen}
                    updateScheduledLessonBlockTime={
                        updateScheduledLessonBlockTime
                    }
                    updateScheduledLessonBlockTimeInFlight={
                        updateScheduledLessonBlockTimeInFlight ?? false
                    }
                />
            ) : null}
            <UpdateLessonBlockTeacherOrPupilModal
                dayIndex={state.values.dayIndex}
                detailType={
                    state.values.updateBlockDetailsModalInfo?.type as
                        | "PUPIL"
                        | "TEACHER"
                        | "TEACHER_AND_DAY"
                        | undefined
                }
                hideModal={() => {
                    dispatchState({
                        input: "updateBlockDetailsModalInfo",
                        value: undefined,
                    });
                }}
                keepFree={state.values.updateBlockDetailsModalInfo?.keepFree}
                lessonBlockIds={state.values.updateBlockDetailsModalInfo?.ids}
                onUpdate={state.values.updateBlockDetailsModalInfo?.onUpdate}
                schoolName={schoolName}
                selectedBlock={state.values.block}
                selectedProfileFullName={
                    state.values.updateBlockDetailsModalInfo
                        ?.selectedProfileFullName
                }
                selectedProfileId={
                    state.values.updateBlockDetailsModalInfo?.selectedProfileId
                }
                selectedStartingYear={state.values.startingYear}
                showModal={
                    Boolean(
                        state.values.updateBlockDetailsModalInfo?.ids?.length,
                    ) &&
                    (state.values.updateBlockDetailsModalInfo?.type ===
                        "TEACHER" ||
                        state.values.updateBlockDetailsModalInfo?.type ===
                            "PUPIL" ||
                        state.values.updateBlockDetailsModalInfo?.type ===
                            "TEACHER_AND_DAY")
                }
                updateLessonBlockDetails={updateLessonBlockDetails}
                updateLessonBlockDetailsInFlight={
                    updateLessonBlockDetailsInFlight
                }
                updateScheduledLessonBlockTime={updateScheduledLessonBlockTime}
                updateScheduledLessonBlockTimeInFlight={
                    updateScheduledLessonBlockTimeInFlight
                }
            />
        </>
    );
};

const SchoolLessons = (props: Props): ReactElement => {
    const {
        dispatchState,
        loadLessonBlocksQueryReference,
        navigation,
        refreshHandler,
        schoolName,
        startingYears,
        state,
    } = props;

    const [dataProvider, setDataProvider] = useState(
        new DataProvider((r1, r2) => {
            return r1 !== r2;
        }),
    );
    const [copyDataToClipboard, setCopyDataToClipboard] =
        useState<() => void | undefined>();

    const [lessonBlockIds, setLessonBlockIds] = useState<string[]>([]);
    const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
    const lessonBlockRefs = useRef(new Map());
    const {
        isOpen: lessonBlockOptionsActionsheetIsOpen,
        onClose: lessonBlockOptionsActionsheetOnClose,
        onOpen: lessonBlockOptionsActionsheetOnOpen,
    } = useDisclose();

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy);
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            navigation.setParams({
                orderBy: orderBy,
            });
        },
        [dispatchState, navigation, state.values.orderBy],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            lessonBlockRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            setSelectedBlocks(isSelected ? lessonBlockIds : []);
        },
        [lessonBlockIds, lessonBlockRefs, setSelectedBlocks],
    );

    const tableHeaders = useMemo(() => {
        return [
            {
                data: (
                    <Checkbox
                        isChecked={
                            selectedBlocks.length != 0 &&
                            lessonBlockIds.length <= selectedBlocks.length &&
                            lessonBlockIds.every((id) =>
                                selectedBlocks.includes(id),
                            )
                        }
                        onChange={selectAllCheckboxChangeHandler}
                        size="md"
                        value="SELECT_ALL"
                    />
                ),
                onPress: () => {
                    return;
                },
            },
            {
                data: "Time",
                onPress: () => sortHandler("lessonDay,lessonTime"),
                icon: sortIconExtractor(
                    "lessonDay,lessonTime",
                    state.values.orderBy,
                ),
            },
            {
                data: "Teacher",
                onPress: () =>
                    sortHandler(
                        "teacher__user__firstName,teacher__user__lastName",
                    ),
                icon: sortIconExtractor(
                    "teacher__user__firstName,teacher__user__lastName",
                    state.values.orderBy,
                ),
            },
            {
                data: "Type",
                onPress: () => sortHandler("lessonStage__lessonType"),
                icon: sortIconExtractor(
                    "lessonStage__lessonType",
                    state.values.orderBy,
                ),
            },
            {
                data: "Stage",
                onPress: () => sortHandler("lessonStage__stage"),
                icon: sortIconExtractor(
                    "lessonStage__stage",
                    state.values.orderBy,
                ),
            },
            {
                data: "Instrument",
                onPress: () => sortHandler("instrument__name"),
                icon: sortIconExtractor(
                    "instrument__name",
                    state.values.orderBy,
                ),
            },
            {
                data: "Pupil",
            },
            {
                data: "Year",
                onPress: () => sortHandler("pupil__schoolYear"),
                icon: sortIconExtractor(
                    "pupil__schoolYear",
                    state.values.orderBy,
                ),
            },
        ];
    }, [
        lessonBlockIds,
        selectAllCheckboxChangeHandler,
        selectedBlocks,
        sortHandler,
        state.values.orderBy,
    ]);

    const toast = useToast();

    const [commitRemoveLessonBlocks, removeLessonBlocksInFlight] =
        useMutation<RemoveLessonBlocksMutation>(remove_lesson_blocks);

    const removeLessonBlocks = useCallback(() => {
        const removeLessonBlocksConfig = {
            variables: {
                connections: state.values.lessonBlocksConnectionId
                    ? [state.values.lessonBlocksConnectionId]
                    : [],
                input: {
                    lessonBlockIds: selectedBlocks,
                },
                startingYear: state.values.startingYear,
                block: state.values.block,
            },
            onCompleted: (response: RemoveLessonBlocksMutation$data) => {
                if (response?.removeLessonBlocks?.success) {
                    toast.show({
                        render: ({ id }: { id: string }) => (
                            <ToastAlert
                                id={id}
                                status="error"
                                title={`Deleted lesson block${
                                    selectedBlocks.length > 1 ? "s" : ""
                                }`}
                                toast={toast}
                            />
                        ),
                    });
                } else {
                    toast.show({
                        render: ({ id }: { id: string }) => (
                            <ToastAlert
                                description={
                                    "Please get in touch with one of the team"
                                }
                                id={id}
                                status="error"
                                title={`Couldn't delete lesson block${
                                    selectedBlocks.length > 1 ? "s" : ""
                                }`}
                                toast={toast}
                            />
                        ),
                    });
                }
                lessonBlockOptionsActionsheetOnClose();
                setSelectedBlocks([]);
                lessonBlockRefs.current.forEach((ref) => {
                    ref.setIsChecked(false);
                });
            },
        };

        commitRemoveLessonBlocks(removeLessonBlocksConfig);
    }, [
        commitRemoveLessonBlocks,
        lessonBlockOptionsActionsheetOnClose,
        selectedBlocks,
        state.values.block,
        state.values.lessonBlocksConnectionId,
        state.values.startingYear,
        toast,
    ]);

    const showUpdateBlockDetailsModalHandler = useCallback(
        (variables: {
            keepFree?: boolean;
            lessonBlockCursor?: string;
            lessonBlockIds: string[];
            onUpdate?: () => void;
            school?: string;
            selectedProfileFullName?: string;
            selectedProfileId?: string;
            staffNoteForTeacher?: string;
            teacherNoteForStaff?: string;
            type: string;
        }) => {
            dispatchState({
                input: "updateBlockDetailsModalInfo",
                value: {
                    ids: variables.lessonBlockIds,
                    type: variables.type as
                        | "INSTRUMENT"
                        | "STAGE"
                        | "PUPIL"
                        | "TEACHER"
                        | "NOTES"
                        | "TEACHER_AND_DAY",
                    school: variables.school ?? schoolName,
                    keepFree: variables.keepFree,
                    selectedProfileId: variables.selectedProfileId,
                    selectedProfileFullName: variables.selectedProfileFullName,
                    teacherNoteForStaff: variables.teacherNoteForStaff,
                    staffNoteForTeacher: variables.staffNoteForTeacher,
                    cursor: variables.lessonBlockCursor,
                    onUpdate: variables.onUpdate,
                },
            });
        },
        [dispatchState, schoolName],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue?.trim(),
            });
            navigation.setParams({
                searchTerm: inputValue?.trim(),
            });
        },
        [dispatchState, navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="-6" px="6" space="4">
                <HStack justifyContent="space-between">
                    <HStack space="4">
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const value = parseInt(itemValue);

                                if (value > state.values.startingYear) {
                                    dispatchState({
                                        input: "block",
                                        value: 1,
                                    });
                                    navigation.setParams({
                                        block: 1,
                                    });
                                }

                                dispatchState({
                                    input: "startingYear",
                                    value: value,
                                });
                                navigation.setParams({
                                    startingYear: value,
                                });
                            }}
                            placeholder="Select year"
                            selectedValue={String(state.values.startingYear)}
                            width="40">
                            {startingYears.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={String(item.value)}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "block",
                                    value: parseInt(itemValue),
                                });
                                navigation.setParams({
                                    block: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select block"
                            selectedValue={String(state.values.block)}
                            width="40">
                            {BLOCKS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            color={
                                typeof state.values.dayIndex === "number"
                                    ? "surface.900"
                                    : "muted.400"
                            }
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const parsedValue =
                                    itemValue !== "ANY"
                                        ? parseInt(itemValue)
                                        : undefined;
                                dispatchState({
                                    input: "dayIndex",
                                    value: parsedValue,
                                });
                                navigation.setParams({
                                    dayIndex: parsedValue,
                                });
                            }}
                            placeholder="Select day"
                            selectedValue={String(
                                state.values.dayIndex ?? "ANY",
                            )}
                            width="40">
                            <Select.Item
                                key={"ANY"}
                                actionSheetLabel={"Any day"}
                                value={"ANY"}
                            />
                            {TEACHING_DAYS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                    </HStack>
                    <Button
                        _hover={{ bg: "primary.500" }}
                        _pressed={{ bg: "primary.600" }}
                        _text={{ fontSize: "17" }}
                        bg="primary.400"
                        leftIcon={<AddCircleIcon size="md" />}
                        onPress={() =>
                            dispatchState({
                                input: "showAddLessonBlockModal",
                                value: true,
                            })
                        }
                        px="4"
                        shadow={1}>
                        Add Lesson Block
                    </Button>
                </HStack>
                <HStack justifyContent="space-between">
                    <HStack space="4" width="400">
                        <Box flex={1}>
                            <SearchBar
                                inputClearHandler={inputClearHandler}
                                inputOnChangeHandler={inputChangeHandler}
                                inputSearchHandler={inputChangeHandler}
                                placeholderText={"Search by pupil or teacher"}
                                searchText={state.values.searchTerm}
                                showSearchIcon
                            />
                        </Box>
                        <Button
                            bg="surface.400"
                            colorScheme="surface"
                            height="10"
                            leftIcon={<RestartIcon size="5" />}
                            onPress={() => refreshHandler()}
                            width="10"
                        />
                    </HStack>
                    <HStack space="4">
                        <PresenceTransition
                            animate={{
                                opacity: 1,
                                scale: 1,
                                transition: {
                                    duration: 250,
                                },
                            }}
                            initial={{
                                opacity: 0,
                                scale: 0,
                            }}
                            visible={selectedBlocks.length > 0}>
                            <Button
                                _text={{ fontSize: "17" }}
                                colorScheme="surface"
                                leftIcon={
                                    <Center size="5">
                                        <OptionsIcon
                                            color="surface.100"
                                            size="5"
                                        />
                                    </Center>
                                }
                                onPress={() =>
                                    lessonBlockOptionsActionsheetOnOpen()
                                }
                                pr="4"
                                shadow={1}>
                                {`View Options (${selectedBlocks.length})`}
                            </Button>
                        </PresenceTransition>
                        <Button
                            _hover={{ bg: "primary.500" }}
                            _pressed={{ bg: "primary.600" }}
                            _text={{ fontSize: "17" }}
                            bg="primary.400"
                            leftIcon={<CopyIcon size="md" />}
                            onPress={copyDataToClipboard}
                            px="4"
                            shadow={1}>
                            Copy Table to Clipboard
                        </Button>
                    </HStack>
                </HStack>
                <Box bg="surface.100" mt="2">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        copyDataToClipboard,
        dispatchState,
        inputChangeHandler,
        inputClearHandler,
        lessonBlockOptionsActionsheetOnOpen,
        navigation,
        refreshHandler,
        selectedBlocks.length,
        startingYears,
        state.values.block,
        state.values.dayIndex,
        state.values.searchTerm,
        state.values.startingYear,
        tableHeaders,
    ]);

    const [dayPickerIsOpen, setDayPickerIsOpen] = useState(false);

    const [commitUpdateLessonBlockDetails] =
        useMutation<UpdateLessonBlockDetailsMutation>(
            update_lesson_block_details,
        );

    // TODO: This is duplicated from the SchoolLessonContent component above. Refactor to pass in as a prop.
    const updateLessonBlockDetails = useCallback(
        (
            variables: {
                archiveBlock?: boolean;
                instrument?: string;
                keepFree?: boolean;
                lessonBlockIds: string[];
                pupilId?: string | null;
                staffNoteForTeacher?: string;
                stageId?: string;
                teacherId?: string | null;
                teacherNoteForStaff?: string;
            },
            lessonBlockCursor?: string,
            onComplete?: () => void,
        ) => {
            const updateLessonBlockDetailsConfig = {
                variables: {
                    connections: state.values.lessonBlocksConnectionId
                        ? [state.values.lessonBlocksConnectionId]
                        : [],
                    input: variables,
                    startingYear: state.values.startingYear,
                    block: state.values.block,
                },
                updater: (store: RecordSourceSelectorProxy) =>
                    lessonBlockConnectionUpdater(
                        store,
                        state.values.lessonBlocksConnectionId,
                        lessonBlockCursor,
                    ),
                onCompleted: (
                    response: UpdateLessonBlockDetailsMutation$data,
                ) => {
                    if (response?.updateLessonBlockDetails?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={`Updated lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't update lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLessonBlockDetails(updateLessonBlockDetailsConfig);
        },
        [
            commitUpdateLessonBlockDetails,
            state.values.block,
            state.values.lessonBlocksConnectionId,
            state.values.startingYear,
            toast,
        ],
    );

    const renderLessonBlockOptionsActionsheet = useMemo(() => {
        return (
            <Actionsheet
                animationType="fade"
                hideDragIndicator
                isOpen={lessonBlockOptionsActionsheetIsOpen}
                justifyContent="center"
                onClose={lessonBlockOptionsActionsheetOnClose}
                size="lg">
                <Actionsheet.Content
                    alignItems="center"
                    closeButtonProps={{ top: "5", right: "5" }}
                    justifyContent="center"
                    mx="auto"
                    roundedBottom={12}
                    roundedTop={12}
                    showCloseButton
                    w="40%">
                    <Text
                        alignSelf="center"
                        fontSize="xl"
                        fontWeight="bold"
                        py="4">
                        {`Lesson Block Actions (${selectedBlocks.length})`}
                    </Text>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <AccountsIcon color="primary.500" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "TEACHER",
                                lessonBlockIds: selectedBlocks,
                                onUpdate: () => {
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            });
                        }}>
                        {`Change block${
                            selectedBlocks.length > 1 ? "s" : ""
                        } to different teacher`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <CalendarIcon color="primary.500" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            setDayPickerIsOpen(true);
                        }}>
                        {`Change block${
                            selectedBlocks.length > 1 ? "s" : ""
                        } to different day`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <UserClockIcon color="primary.600" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "TEACHER_AND_DAY",
                                lessonBlockIds: selectedBlocks,
                                onUpdate: () => {
                                    refreshHandler();
                                },
                            });
                        }}>
                        {`Change block to different ${
                            selectedBlocks.length > 1 ? "s" : ""
                        } teacher and day`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <UserSlashIcon color="error.500" size="5" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            updateLessonBlockDetails(
                                {
                                    lessonBlockIds: selectedBlocks,
                                    pupilId: null,
                                    archiveBlock: true,
                                },
                                undefined,
                                () => {
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            );
                        }}>
                        {`Remove assigned pupil from block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                <EditIcon color="secondary.600" size="6" />
                            </Center>
                        }
                        onPress={() => {
                            lessonBlockOptionsActionsheetOnClose();
                            showUpdateBlockDetailsModalHandler({
                                type: "NOTES",
                                lessonBlockIds: selectedBlocks,
                                onUpdate: () => {
                                    setSelectedBlocks([]);
                                    lessonBlockRefs.current.forEach((ref) => {
                                        ref.setIsChecked(false);
                                    });
                                },
                            });
                        }}>
                        {`Add/update note for lesson block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                    <Actionsheet.Item
                        _text={{ fontSize: "lg" }}
                        leftIcon={
                            <Center size="7">
                                {removeLessonBlocksInFlight ? (
                                    <Spinner color="surface.800" size="sm" />
                                ) : (
                                    <TrashIcon color="surface.800" size="5" />
                                )}
                            </Center>
                        }
                        onPress={removeLessonBlocks}>
                        {`Delete lesson block${
                            selectedBlocks.length > 1 ? "s" : ""
                        }`}
                    </Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
        );
    }, [
        lessonBlockOptionsActionsheetIsOpen,
        lessonBlockOptionsActionsheetOnClose,
        refreshHandler,
        removeLessonBlocks,
        removeLessonBlocksInFlight,
        selectedBlocks,
        showUpdateBlockDetailsModalHandler,
        updateLessonBlockDetails,
    ]);

    return (
        <Box flex={1}>
            {renderHeader}
            {loadLessonBlocksQueryReference != null ? (
                <Suspense
                    fallback={<LoadingBlobs>Loading Lessons...</LoadingBlobs>}>
                    <SchoolLessonsContent
                        {...props}
                        dataProvider={dataProvider}
                        dayPickerIsOpen={dayPickerIsOpen}
                        lessonBlockRefs={lessonBlockRefs}
                        loadLessonBlocksQueryReference={
                            loadLessonBlocksQueryReference
                        }
                        refreshHandler={refreshHandler}
                        selectedBlocks={selectedBlocks}
                        setCopyDataToClipboard={setCopyDataToClipboard}
                        setDataProvider={setDataProvider}
                        setDayPickerIsOpen={setDayPickerIsOpen}
                        setLessonBlockIds={setLessonBlockIds}
                        setSelectedBlocks={setSelectedBlocks}
                        showUpdateBlockDetailsModalHandler={
                            showUpdateBlockDetailsModalHandler
                        }
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Lessons...</LoadingBlobs>
            )}
            {renderLessonBlockOptionsActionsheet}
        </Box>
    );
};

export default React.memo(SchoolLessons);
